/*!
Theme Name: Sandbox - Modern & Multipurpose Bootstrap 5 Template
Theme URI:	https://sandbox.elemisthemes.com/
Version:	3.2.2
Author:		elemis
*/
/* -------------------------------------------------------

 01. Basics style
 02. Helper style
 03. Selection style 
 04. Preloader style
 05. Owl-Theme custom style
 06. Section style
 07. Navbar style
 08. Header style
 09. Slider-fade style (Homepage Slider)
 10. Slider style (Inner Page Slider)

 10.5 Calendar

 11. Kenburns Slider style
 12. Slider Grid Background style
 13. Page Banner Header style
 14. Video Background style
 15. Team style
 16. Rooms 2 style
 17. Rooms 3 style
 18. Rooms Page style
 19. Services style
 20. Restaurant Menu style
 21. Facilties style
 22. Clients style
 23. Gallery style
 24. Promo Video and Testimonials style
 25. Booking style
 26. Pricing style
 27. Careers style
 28. Accordion Box (for Faqs) style
 29. News 1 style
 30. News 2 style
 31. Post style
 32. Contact style
 33. Buttons style
 34. Coming Soon
 35. 404 Page style
 36. Footer style
 37. toTop Button style
 38. Overlay Effect Bg image style
 39. Responsive (Media Query)

------------------------------------------------------- */
/* ======= Basic style ======= */
html,
body {
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  -moz-font-smoothing: antialiased;
  font-smoothing: antialiased;
}

* {
  margin: 0;
  padding: 0;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  outline: none;
  list-style: none;
  word-wrap: break-word;
}

body {
  font-family: "Barlow", sans-serif;
  font-size: 15px;
  font-weight: 400;
  line-height: 1.75em;
  color: #666;
  overflow-x: hidden !important;
}

p {
  font-family: "Barlow", sans-serif;
  font-size: 15px;
  font-weight: 400;
  line-height: 1.75em;
  color: #666;
  margin-bottom: 20px;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: "Gilda Display", serif;
  font-weight: 400;
  line-height: 1.25em;
  margin: 0 0 20px 0;
  color: #222;
}

/*img homa*/
.logosmall {
  display: inline-block;
  width: 96px;
  vertical-align: sub;
}

.introsmall4 {
  max-width: 50px;
  vertical-align: text-top;
  margin-right: 2px;
}

img {
  width: 100%;
  height: auto;
}

img {
  -webkit-transition: all 0.5s;
  -o-transition: all 0.5s;
  transition: all 0.5s;
  color: #f4f4f4;
}

span,
a,
a:hover {
  display: inline-block;
  text-decoration: none;
  color: inherit;
}

b {
  font-weight: 400;
  color: #1ac4ff;
}

/* text field */
input[type=password]:focus,
input[type=email]:focus,
input[type=text]:focus,
input[type=file]:focus,
input[type=radio]:focus,
input[type=checkbox]:focus,
textarea:focus {
  outline: none;
}

input[type=password],
input[type=email],
input[type=text],
input[type=file],
textarea {
  max-width: 100%;
  margin-bottom: 15px;
  padding: 10px 0;
  height: auto;
  background-color: transparent;
  -webkit-box-shadow: none;
  box-shadow: none;
  border-width: 0 0 1px;
  border-style: solid;
  display: block;
  width: 100%;
  line-height: 1.5em;
  font-family: "Barlow", sans-serif;
  font-size: 15px;
  font-weight: 400;
  color: #666;
  background-image: none;
  border-bottom: 1px solid #ececec;
  border-color: ease-in-out 0.15s, box-shadow ease-in-out 0.15s;
}

input:focus,
textarea:focus {
  border-bottom-width: 1px;
  border-color: #1ac4ff;
}

input[type=submit],
input[type=reset],
input[type=button],
button {
  text-shadow: none;
  -webkit-box-shadow: none;
  box-shadow: none;
  line-height: 1.75em;
  -webkit-transition: background-color 0.15s ease-out;
  transition: background-color 0.15s ease-out;
  background: transparent;
  border: 0px solid transparent;
}

input[type=submit]:hover,
input[type=reset]:hover,
input[type=button]:hover,
button:hover {
  border: 0px solid transparent;
}

select {
  padding: 10px;
  border-radius: 5px;
}

table,
th,
tr,
td {
  border: 1px solid #f8f5f0;
}

th,
tr,
td {
  padding: 10px;
}

input[type=radio],
input[type=checkbox] {
  display: inline;
}

input[type=submit] {
  font-weight: 400;
  font-family: "Gilda Display", serif;
  text-transform: uppercase;
  background: #1ac4ff;
  color: #fff;
  padding: 8px 24px;
  margin: 0;
  position: relative;
  font-size: 15px;
  letter-spacing: 3px;
}

.alert-success {
  background: transparent;
  color: #666;
  border: 1px solid #1ac4ff;
  border-radius: 0px;
}

/* placeholder */
::-webkit-input-placeholder {
  color: #666;
  font-size: 15px;
  font-weight: 400;
}

:-moz-placeholder {
  color: #666;
}

::-moz-placeholder {
  color: #666;
  opacity: 1;
}

:-ms-input-placeholder {
  color: #666;
}

/* blockquote */
blockquote {
  padding: 45px;
  display: block;
  position: relative;
  background-color: #f8f5f0;
  overflow: hidden;
  margin: 35px 0;
  font-size: 15px;
  font-weight: 400;
  line-height: 1.75em;
  color: #666;
}

blockquote p {
  font-family: inherit;
  margin-bottom: 0 !important;
  color: inherit;
  max-width: 650px;
  width: 100%;
  position: relative;
  z-index: 3;
}

blockquote:before {
  content: "\e645";
  font-family: "Themify";
  position: absolute;
  right: 40px;
  bottom: 40px;
  font-size: 110px;
  opacity: 0.07;
  line-height: 1;
  color: #222;
}

blockquote p {
  margin-bottom: 0;
}

blockquote p a {
  color: inherit;
}

blockquote cite {
  display: inline-block;
  font-size: 15px;
  position: relative;
  padding-left: 60px;
  border-color: inherit;
  line-height: 1;
  margin-top: 22px;
  font-style: normal;
  color: #1ac4ff;
  font-weight: 400;
}

blockquote cite:before {
  content: "";
  position: absolute;
  left: 0;
  bottom: 5px;
  width: 45px;
  height: 1px;
  border-top: 1px solid;
  border-color: inherit;
  color: #1ac4ff;
}

/* ======= Helper style ======= */
.mt-0 {
  margin-top: 0 !important;
}

.mt-15 {
  margin-top: 15px !important;
}

.mt-20 {
  margin-top: 20px !important;
}

.mt-30 {
  margin-top: 30px !important;
}

.mt-60 {
  margin-top: 60px !important;
}

.mt-90 {
  margin-top: 90px !important;
}

.mb-0 {
  margin-bottom: 0 !important;
}

.mb-30 {
  margin-bottom: 30px !important;
}

.mb-60 {
  margin-bottom: 60px !important;
}

.mb-90 {
  margin-bottom: 90px !important;
}

.pb-0 {
  padding-bottom: 0 !important;
}

.no-padding {
  padding: 0 !important;
}

/* important */
.o-hidden {
  overflow: hidden;
}

.position-re {
  position: relative;
}

.full-width {
  width: 100%;
}

.bg-img {
  background-size: cover;
  background-repeat: no-repeat;
}

.bg-fixed {
  background-attachment: fixed;
}

.pattern {
  background-repeat: repeat;
  background-size: auto;
}

.bold {
  font-weight: 600;
}

.count {
  font-family: "Gilda Display", serif;
}

.valign {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}

.v-middle {
  position: absolute;
  width: 100%;
  top: 50%;
  left: 0;
  -webkit-transform: translate(0%, -50%);
  transform: translate(0%, -50%);
}

.v-bottom {
  position: absolute;
  width: 100%;
  bottom: 5%;
  left: 0;
  -webkit-transform: translate(0%, -5%);
  transform: translate(0%, -5%);
}

.js .animate-box {
  opacity: 0;
}

/* background & color */
.bg-black {
  background: #2b96cc;
}

.bg-cream {
  background: #e9e9e9;
}

.color-1 {
  color: #fff;
}

.color-2 {
  color: #4c4c4c;
}

.color-3 {
  color: #666;
}

.color-4 {
  color: #222;
}

.color-5 {
  color: #1ac4ff;
}

/* star - white  */
.star {
  position: relative;
  display: inline-block;
  width: 0;
  height: 0;
  margin-left: 0.9em;
  margin-right: 0.9em;
  margin-bottom: 1.2em;
  border-right: 0.3em solid transparent;
  border-bottom: 0.7em solid #fff;
  border-left: 0.3em solid transparent;
  /* Controlls the size of the stars. */
  font-size: 8px;
}

.star:before, .star:after {
  content: "";
  display: block;
  width: 0;
  height: 0;
  position: absolute;
  top: 0.6em;
  left: -1em;
  border-right: 1em solid transparent;
  border-bottom: 0.7em solid #fff;
  border-left: 1em solid transparent;
  transform: rotate(-35deg);
}

.star:after {
  transform: rotate(35deg);
}

/* Star rating  */
.star-rating {
  position: relative;
  display: inline-block;
  width: 0;
  height: 0;
  margin-left: 0.9em;
  margin-right: 0.9em;
  margin-bottom: 2em;
  border-right: 0.3em solid transparent;
  border-bottom: 0.7em solid #1ac4ff;
  border-left: 0.3em solid transparent;
  /* Controlls the size of the stars. */
  font-size: 8px;
}

.star-rating:before, .star-rating:after {
  content: "";
  display: block;
  width: 0;
  height: 0;
  position: absolute;
  top: 0.6em;
  left: -1em;
  border-right: 1em solid transparent;
  border-bottom: 0.7em solid #1ac4ff;
  border-left: 1em solid transparent;
  transform: rotate(-35deg);
}

.star-rating:after {
  transform: rotate(35deg);
}

/* ======= Selection style ======= */
::-webkit-selection {
  color: #fff;
  background: #1ac4ff;
}

::-moz-selection {
  color: #fff;
  background: #1ac4ff;
}

::selection {
  color: #fff;
  background: #1ac4ff;
}

/* ======= Preloader style ======= */
.preloader-bg,
#preloader {
  position: fixed;
  width: 100%;
  height: 100%;
  overflow: hidden;
  background: #fff;
  z-index: 999999;
}

#preloader {
  display: table;
  table-layout: fixed;
}

#preloader-status {
  display: table-cell;
  vertical-align: middle;
}

.preloader-position {
  position: relative;
  margin: 0 auto;
  text-align: center;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  -ms-box-sizing: border-box;
  -o-box-sizing: border-box;
  box-sizing: border-box;
}

.loader {
  position: relative;
  width: 55px;
  height: 55px;
  left: 50%;
  top: auto;
  margin-left: -22px;
  margin-top: 1px;
  -webkit-animation: rotate 1s infinite linear;
  -moz-animation: rotate 1s infinite linear;
  -ms-animation: rotate 1s infinite linear;
  -o-animation: rotate 1s infinite linear;
  animation: rotate 1s infinite linear;
  border: 1px solid rgba(0, 0, 0, 0.1);
  -webkit-border-radius: 50%;
  -moz-border-radius: 50%;
  -ms-border-radius: 50%;
  -o-border-radius: 50%;
  border-radius: 50%;
}

.loader span {
  position: absolute;
  width: 55px;
  height: 55px;
  top: -1px;
  left: -1px;
  border: 1px solid transparent;
  border-top: 1px solid #1ac4ff;
  -webkit-border-radius: 50%;
  -moz-border-radius: 50%;
  -ms-border-radius: 50%;
  -o-border-radius: 50%;
  border-radius: 50%;
}

@-webkit-keyframes rotate {
  0% {
    -webkit-transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
  }
}
@keyframes rotate {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
/* ======= Owl-Theme custom style ======= */
.owl-theme .owl-nav.disabled {
  margin-top: 0px;
  line-height: 1.5;
  display: none;
  outline: none;
}

.owl-theme .owl-dots .owl-dot span {
  width: 12px;
  height: 12px;
  margin: 0 3px;
  border-radius: 50%;
  background: transparent;
  border: 1px solid #949494;
}

.owl-theme .owl-dots .owl-dot.active span,
.owl-theme .owl-dots .owl-dot:hover span {
  background: #2b96cc;
  border: 1px solid #2b96cc;
}

/* === owl carousel nav style  === */
.clients .owl-theme .owl-nav,
.team .owl-theme .owl-nav,
.slider-grid-bg .owl-theme .owl-nav,
.news .owl-theme .owl-nav,
.pricing .owl-theme .owl-nav,
.testimonials .owl-theme .owl-nav,
.rooms-page .owl-theme .owl-nav,
.rooms3 .owl-theme .owl-nav,
.cursos .owl-theme .owl-nav,
.rooms1 .owl-theme .owl-nav {
  position: absolute !important;
  top: 35% !important;
  bottom: auto !important;
  width: 100%;
}

.clients .owl-theme .owl-nav,
.team .owl-theme .owl-nav,
.slider-grid-bg .owl-theme .owl-nav,
.news .owl-theme .owl-nav,
.pricing .owl-theme .owl-nav,
.testimonials .owl-theme .owl-nav,
.rooms-page .owl-theme .owl-nav,
.rooms3 .owl-theme .owl-nav,
.cursos .owl-theme .owl-nav,
.rooms1 .owl-theme .owl-nav {
  position: relative;
  position: absolute;
  bottom: 0;
  left: 50%;
  -webkit-transform: translateX(-50%);
  -ms-transform: translateX(-50%);
  transform: translateX(-50%);
}

.clients .owl-theme .owl-prev,
.team .owl-theme .owl-prev,
.slider-grid-bg .owl-theme .owl-prev,
.news .owl-theme .owl-prev,
.pricing .owl-theme .owl-prev,
.testimonials .owl-theme .owl-prev,
.rooms-page .owl-theme .owl-prev,
.rooms3 .owl-theme .owl-prev,
.cursos .owl-theme .owl-prev,
.rooms1 .owl-theme .owl-prev {
  left: 10px !important;
}

.clients .owl-theme .owl-next,
.team .owl-theme .owl-next,
.slider-grid-bg .owl-theme .owl-next,
.news .owl-theme .owl-next,
.pricing .owl-theme .owl-next,
.testimonials .owl-theme .owl-next,
.rooms-page .owl-theme .owl-next,
.rooms3 .owl-theme .owl-next,
.cursos .owl-theme .owl-next,
.rooms1 .owl-theme .owl-next {
  right: 10px !important;
}

.clients .owl-theme .owl-prev,
.clients .owl-theme .owl-next,
.team .owl-theme .owl-prev,
.team .owl-theme .owl-next,
.slider-grid-bg .owl-theme .owl-prev,
.slider-grid-bg .owl-theme .owl-next,
.news .owl-theme .owl-prev,
.news .owl-theme .owl-next,
.pricing .owl-theme .owl-prev,
.pricing .owl-theme .owl-next,
.testimonials .owl-theme .owl-prev,
.testimonials .owl-theme .owl-next,
.rooms-page .owl-theme .owl-prev,
.rooms-page .owl-theme .owl-next,
.rooms3 .owl-theme .owl-prev,
.rooms3 .owl-theme .owl-next,
.cursos .owl-theme .owl-prev,
.cursos .owl-theme .owl-next,
.rooms1 .owl-theme .owl-prev,
.rooms1 .owl-theme .owl-next {
  color: #fff;
  position: absolute !important;
  top: 50%;
  padding: 0;
  height: 50px;
  width: 50px;
  border-radius: 0%;
  -webkit-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
  background: #272727;
  border: 1px solid #272727;
  line-height: 0;
  text-align: center;
  font-size: 16px;
}

.clients .owl-theme .owl-prev > span,
.clients .owl-theme .owl-next > span,
.team .owl-theme .owl-prev > span,
.team .owl-theme .owl-next > span,
.slider-grid-bg .owl-theme .owl-prev > span,
.slider-grid-bg .owl-theme .owl-next > span,
.news .owl-theme .owl-prev > span,
.news .owl-theme .owl-next > span,
.pricing .owl-theme .owl-prev > span,
.pricing .owl-theme .owl-next > span,
.testimonials .owl-theme .owl-prev > span,
.testimonials .owl-theme .owl-next > span,
.rooms-page .owl-theme .owl-prev > span,
.rooms-page .owl-theme .owl-next > span,
.rooms3 .owl-theme .owl-prev > span,
.rooms3 .owl-theme .owl-next > span,
.cursos .owl-theme .owl-prev > span,
.cursos .owl-theme .owl-next > span,
.rooms1 .owl-theme .owl-prev > span,
.rooms1 .owl-theme .owl-next > span {
  position: absolute;
  line-height: 0;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
}

.clients .owl-theme .owl-nav [class*=owl-],
.team .owl-theme .owl-nav [class*=owl-],
.slider-grid-bg .owl-theme .owl-nav [class*=owl-],
.news .owl-theme .owl-nav [class*=owl-],
.pricing .owl-theme .owl-nav [class*=owl-],
.testimonials .owl-theme .owl-nav [class*=owl-],
.rooms-page .owl-theme .owl-nav [class*=owl-],
.rooms3 .owl-theme .owl-nav [class*=owl-],
.cursos .owl-theme .owl-nav [class*=owl-],
.rooms1 .owl-theme .owl-nav [class*=owl-] {
  width: 40px;
  height: 40px;
  line-height: 34px;
  background: transparent;
  color: #fff;
  font-size: 11px;
  margin-right: 15px;
  margin-left: 15px;
  cursor: pointer;
  border: 1px solid rgba(255, 255, 255, 0.7);
  border-radius: 100%;
  transition: all 0.2s ease-in-out;
  transform: scale(1);
}

.clients .owl-theme .owl-nav [class*=owl-]:hover,
.team .owl-theme .owl-nav [class*=owl-]:hover,
.slider-grid-bg .owl-theme .owl-nav [class*=owl-]:hover,
.news .owl-theme .owl-nav [class*=owl-]:hover,
.pricing .owl-theme .owl-nav [class*=owl-]:hover,
.testimonials .owl-theme .owl-nav [class*=owl-]:hover,
.rooms-page .owl-theme .owl-nav [class*=owl-]:hover,
.rooms3 .owl-theme .owl-nav [class*=owl-]:hover,
.cursos .owl-theme .owl-nav [class*=owl-]:hover,
.rooms1 .owl-theme .owl-nav [class*=owl-]:hover {
  transform: scale(0.9);
  background: transparent;
  border: 1px solid #1ac4ff;
  color: #FFF;
}

@media screen and (max-width: 768px) {
  .clients .owl-theme .owl-nav,
.team .owl-theme .owl-nav,
.slider-grid-bg .owl-theme .owl-nav,
.news .owl-theme .owl-nav,
.pricing .owl-theme .owl-nav,
.testimonials .owl-theme .owl-nav,
.rooms-page .owl-theme .owl-nav,
.rooms3 .owl-theme .owl-nav,
.cursos .owl-theme .owl-nav,
.rooms1 .owl-theme .owl-nav {
    display: none;
  }
}
/* ======= Section style ======= */
.section-padding {
  padding: 120px 0;
}

.section-padding2 {
  padding: 0 0 120px 0;
}

.section-padding h6 {
  color: #1ac4ff;
  font-size: 20px;
  margin-bottom: 20px;
}

.section-subtitle {
  font-size: 15px;
  font-family: "Barlow Condensed", sans-serif;
  font-weight: 400;
  color: #666;
  text-transform: uppercase;
  position: relative;
  letter-spacing: 6px;
  margin-bottom: 5px;
}

.section-subtitle span {
  color: #1ac4ff;
}

.section-title {
  font-size: 45px;
  font-family: "Gilda Display", serif;
  font-weight: 400;
  color: #222;
  position: relative;
  margin-bottom: 20px;
  line-height: 1.25em;
}

.section-title span {
  color: #222;
}

/* ======= Navbar style ======= */
.navbar {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  background: transparent;
  z-index: 99;
  padding-right: 0;
  padding-left: 0;
  padding-top: 30px;
  padding-bottom: 0;
  height: 90px;
}

.navbar .icon-bar {
  color: #1ac4ff;
}

.navbar .navbar-nav .nav-link {
  font-family: "Barlow Condensed", sans-serif;
  font-size: 15px;
  font-weight: 400;
  text-transform: uppercase;
  letter-spacing: 3px;
  color: #fff;
  margin: 3px;
  -webkit-transition: all 0.4s;
  transition: all 0.4s;
}

.navbar .navbar-nav .nav-link.nav-color {
  color: #fff;
}

.navbar .navbar-nav .nav-link:hover {
  color: #2b96cc;
}

.navbar .navbar-nav .active {
  color: #2b96cc !important;
}

.nav-scroll {
  background: #fff;
  padding: 0 0 0 0;
  position: fixed;
  top: -100px;
  left: 0;
  width: 100%;
  -webkit-transition: -webkit-transform 0.5s;
  transition: -webkit-transform 0.5s;
  transition: transform 0.5s;
  transition: transform 0.5s, -webkit-transform 0.5s;
  -webkit-transform: translateY(100px);
  transform: translateY(100px);
  -webkit-box-shadow: 0px 5px 15px rgba(0, 0, 0, 0.05);
  box-shadow: 0px 5px 15px rgba(0, 0, 0, 0.05);
}

.nav-scroll .icon-bar {
  color: #1ac4ff;
}

.nav-scroll .navbar-nav .nav-link {
  color: #222;
}

.nav-scroll .navbar-nav .active {
  color: #1ac4ff !important;
}

.nav-scroll .navbar-nav .logo {
  padding: 15px 0;
  color: #222;
}

.nav-scroll .logo-img {
  width: 160px;
  margin-bottom: 0px;
}

.nav-scroll .logo-wrapper .logo h2 {
  font-family: "Gilda Display", serif;
  font-size: 24px;
  font-weight: 400;
  text-transform: uppercase;
  display: block;
  width: 100%;
  position: relative;
  color: #1ac4ff;
  letter-spacing: 3px;
  margin-bottom: 0;
}

.nav-scroll .logo-wrapper .logo span {
  display: block;
  font-family: "Barlow Condensed", sans-serif;
  font-size: 10px;
  color: #222;
  font-weight: 400;
  letter-spacing: 7px;
  display: block;
  margin-top: 0px;
  line-height: 1em;
  text-transform: uppercase;
}

.nav-scroll .logo-wrapper {
  float: left;
}

.logo-wrapper {
  float: left;
  padding-left: 0;
}

.logo-wrapper .logo h2 {
  font-family: "Gilda Display", serif;
  font-size: 24px;
  font-weight: 400;
  text-transform: uppercase;
  display: block;
  width: 100%;
  position: relative;
  color: #1ac4ff;
  letter-spacing: 3px;
  margin-bottom: 0;
}

.logo-wrapper .logo span {
  display: block;
  font-family: "Barlow Condensed", sans-serif;
  font-size: 10px;
  color: #fff;
  font-weight: 400;
  letter-spacing: 7px;
  display: block;
  margin-top: 0px;
  line-height: 1em;
  text-transform: uppercase;
}

.logo {
  padding: 0;
  text-align: center;
}

.logo-img {
  width: 160px;
  margin-bottom: 0px;
}

.dropdown:hover .dropdown-menu,
.dropdown:hover .mega-menu {
  opacity: 1;
  visibility: visible;
  -webkit-transform: translateY(0);
  transform: translateY(0);
}

.dropdown .nav-link {
  cursor: pointer;
}

.dropdown .nav-link i {
  padding-left: 0px;
  font-size: 8px;
}

.dropdown-item {
  position: relative;
}

.dropdown-item:hover .sub-menu {
  opacity: 1;
  visibility: visible;
  -webkit-transform: translateY(0);
  transform: translateY(0);
}

.dropdown-item span {
  display: block;
  cursor: pointer;
}

.dropdown-menu,
.sub-menu {
  display: block;
  opacity: 0;
  visibility: hidden;
  -webkit-transform: translateY(20px);
  transform: translateY(20px);
  width: 200px;
  padding: 15px 10px;
  border-radius: 0;
  border: 0;
  background-color: #fff;
  -webkit-transition: all 0.3s;
  transition: all 0.3s;
}

.dropdown-menu .dropdown-item,
.sub-menu .dropdown-item {
  padding: 4px 15px;
  font-family: "Barlow", sans-serif;
  font-size: 15px;
  font-weight: 400;
  color: #222;
  position: relative;
  -webkit-transition: all 0.4s;
  transition: all 0.4s;
  border-bottom: 1px solid #f4f4f4;
  background-color: transparent;
}

.dropdown-menu .dropdown-item:last-child,
.sub-menu .dropdown-item:last-child {
  border-bottom: none;
}

.dropdown-menu .dropdown-item:hover,
.sub-menu .dropdown-item:hover {
  background: transparent;
  padding-left: 20px;
  color: #222;
}

.dropdown-menu .dropdown-item a,
.sub-menu .dropdown-item a {
  display: block;
}

.dropdown-menu .dropdown-item i,
.sub-menu .dropdown-item i {
  padding: 13px 0 0 5px;
  font-size: 8px;
  float: right;
}

.dropdown-menu .dropdown-item em,
.sub-menu .dropdown-item em {
  font-size: 10px;
  background-color: #1ac4ff;
  padding: 3px 6px;
  color: #fff;
  border-radius: 0px;
  position: absolute;
  margin-left: 10px;
  top: 10px;
  font-style: normal;
  line-height: 1.5em;
}

.dropdown-menu.last {
  left: -20%;
}

.sub-menu {
  position: absolute;
  left: 100%;
  top: -5px;
}

.sub-menu.left {
  left: -100%;
  margin-left: -20px;
}

.mega-menu {
  position: absolute;
  opacity: 0;
  visibility: hidden;
  -webkit-transform: translateY(20px);
  transform: translateY(20px);
  right: -150px;
  width: 800px;
  background-color: #fff;
  -webkit-box-shadow: 0px 10px 20px rgba(0, 0, 0, 0.05);
  box-shadow: 0px 10px 20px rgba(0, 0, 0, 0.05);
  -webkit-transition: all 0.3s;
  transition: all 0.3s;
}

.mega-menu .dropdown-title {
  display: block;
  color: #fff;
  font-size: 15px;
  font-weight: 400;
  letter-spacing: 5px;
  text-transform: uppercase;
  margin-bottom: 15px;
  padding: 0 15px 15px;
  border-bottom: 1px solid rgba(255, 255, 255, 0.03);
}

.mega-menu .dropdown-menu {
  position: static !important;
  float: left;
  -webkit-box-shadow: none;
  box-shadow: none;
  border-right: 1px solid rgba(255, 255, 255, 0.03);
  border-bottom: 1px solid rgba(255, 255, 255, 0.03);
  -webkit-transition: all 0.3s;
  transition: all 0.3s;
}

@media screen and (max-width: 767px) {
  .navbar {
    padding-left: 0px;
    padding-right: 0px;
    background: transparent;
    padding-top: 15px;
    padding-bottom: 15px;
  }

  .nav-scroll {
    background: #fff;
  }

  .logo-wrapper {
    float: left;
    padding: 15px;
  }

  .nav-scroll .logo-wrapper {
    padding: 15px;
    background-color: #fff;
  }
}
/* ======= Header style ======= */
.header {
  min-height: 100vh;
  overflow: hidden;
}

.header .caption .o-hidden {
  display: inline-block;
}

.header .caption h2 {
  font-weight: 400;
  font-size: 30px;
}

.header .caption span i.star-rating {
  -webkit-animation-delay: 0.8s;
  animation-delay: 0.8s;
}

.header .caption h4 {
  font-family: "Gilda Display", serif;
  font-size: 15px;
  font-weight: 400;
  text-transform: uppercase;
  letter-spacing: 6px;
  color: #fff;
  margin-bottom: 20px;
  margin-top: 20px;
  -webkit-animation-delay: 0.4s;
  animation-delay: 0.4s;
}

.header .caption h1 {
  position: relative;
  margin-bottom: 20px;
  font-family: "Barlow Condensed", serif;
  font-size: 55px;
  text-transform: uppercase;
  line-height: 1.35em;
  -webkit-animation-delay: 0.6s;
  animation-delay: 0.6s;
  color: #fff;
  font-weight: 400;
  letter-spacing: 15px;
}

.header .caption h3 {
  position: relative;
  margin-bottom: 20px;
  font-family: "Gilda Display", serif;
  font-size: 48px;
  text-transform: uppercase;
  line-height: 1.35em;
  -webkit-animation-delay: 0.6s;
  animation-delay: 0.6s;
  color: #fff;
  font-weight: 400;
  letter-spacing: 7px;
}

.header .caption p {
  font-size: 18px;
  line-height: 1.75em;
  margin-bottom: 0;
  color: #fff;
  -webkit-animation-delay: 0.6s;
  animation-delay: 0.6s;
  margin-right: 15px;
}

.header .caption p span {
  letter-spacing: 5px;
  text-transform: uppercase;
  margin: 0 5px;
  padding-right: 14px;
  position: relative;
}

.header .caption p span:last-child {
  padding: 0;
}

.header .caption p span:last-child:after {
  display: none;
}

.header .caption p span:after {
  content: "";
  width: 7px;
  height: 7px;
  border-radius: 50%;
  background: #222;
  position: absolute;
  top: 10px;
  right: 0;
  opacity: 0.5;
}

.header .caption .butn-light {
  -webkit-animation-delay: 0.8s;
  animation-delay: 0.8s;
}

.header .caption .butn-dark {
  -webkit-animation-delay: 0.8s;
  animation-delay: 0.8s;
}

.header .social a {
  color: #eee;
  font-size: 20px;
  margin: 10px 15px;
}

hr {
  margin-top: 5px;
  margin-bottom: 20px;
  border: 0;
  border-top: 1px solid rgba(255, 255, 255, 0.3);
}

/* ======= Slider-fade style (Homepage Slider) ======= */
.slider-fade .owl-item {
  height: 100vh;
  position: relative;
}

.slider-fade .item {
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  background-size: cover;
  background-position: center;
}

.slider-fade .item .caption {
  z-index: 9;
}

/* slider-fade owl-nav next and prev */
.slider-fade .owl-nav {
  position: absolute;
  bottom: 7%;
  right: 12%;
}

.slider-fade .owl-prev {
  float: left;
}

.slider-fade .owl-next {
  float: right;
}

.slider-fade .owl-theme .owl-nav [class*=owl-] {
  width: 40px;
  height: 40px;
  line-height: 34px;
  background: transparent;
  border-radius: 100%;
  color: #fff;
  font-size: 10px;
  margin-right: 3px;
  cursor: pointer;
  border: 1px solid rgba(255, 255, 255, 0.5);
  transition: all 0.2s ease-in-out;
  transform: scale(1);
}

.owl-theme .owl-nav [class*=owl-]:hover {
  transform: scale(0.9);
  background: transparent;
  border: 1px solid #1ac4ff;
  color: #fff;
}

@media screen and (max-width: 768px) {
  .slider-fade .owl-nav {
    display: none;
  }
}
/* owl-dots for slider */
.slider-fade .owl-dots {
  position: absolute;
  text-align: center;
  top: 50%;
  right: 5%;
  -webkit-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
  width: 40px;
  text-align: right !important;
}

.slider-fade .owl-dots:after {
  position: absolute;
  top: -70px;
  left: 50%;
  right: 0;
  -webkit-transform: translateX(50%);
  -ms-transform: translateX(50%);
  transform: translateX(50%);
  width: 1px;
  height: 60px;
  content: "";
  background: rgba(255, 255, 255, 0.3);
}

.slider-fade .owl-dots:before {
  position: absolute;
  bottom: -70px;
  left: 50%;
  right: 0;
  -webkit-transform: translateX(50%);
  -ms-transform: translateX(50%);
  transform: translateX(50%);
  width: 1px;
  height: 60px;
  content: "";
  background: rgba(255, 255, 255, 0.3);
}

.slider-fade .owl-dots .owl-dot {
  text-align: center;
}

.slider-fade .owl-dots {
  counter-reset: dots;
}

.slider-fade .owl-dots .owl-dot {
  position: relative;
  -webkit-transition: 0.3s all ease;
  -o-transition: 0.3s all ease;
  transition: 0.3s all ease;
  display: block;
  color: #fff;
  font-family: "Barlow", sans-serif;
}

.slider-fade .owl-dots .owl-dot span {
  background: transparent;
  display: none;
}

.slider-fade .owl-dots .owl-dot.active {
  color: #1ac4ff;
}

.slider-fade .owl-dot:before {
  counter-increment: dots;
  content: counter(dots);
}

/* video field for slider */
.header div.reservation {
  -webkit-writing-mode: vertical-lr;
  -ms-writing-mode: tb-lr;
  writing-mode: vertical-lr;
  -webkit-transform: rotate(180deg);
  -ms-transform: rotate(180deg);
  transform: rotate(180deg);
  text-orientation: sideways;
  text-orientation: sideways;
  margin: 0;
  position: absolute;
  top: 0;
  left: 40px;
  height: 60%;
  z-index: 9;
}

.header div.reservation .call {
  color: #fff;
  text-transform: uppercase;
  font-family: "Barlow Condensed", sans-serif;
  font-weight: 400;
  letter-spacing: 5px;
  font-size: 12px;
  line-height: 1.75em;
  margin-top: 15px;
}

.header div.reservation .call span {
  font-family: "Gilda Display", serif;
  font-size: 24px;
  color: #ffffff;
  letter-spacing: 1px;
}

.header div.reservation .icon {
  position: absolute;
  left: -1px;
  top: -50px;
  width: 50px;
  height: 50px;
  border: 1px solid rgba(255, 255, 255, 0.5);
  -webkit-animation: pulse 2s infinite;
  animation: pulse 2s infinite;
  border-radius: 100%;
}

.header div.reservation a .icon i {
  color: #FFF;
  font-size: 24px;
  transform: rotate(90deg);
}

.header div.reservation a .icon:hover {
  border: 1px solid #1ac4ff;
}

@media screen and (max-width: 768px) {
  .header div.reservation {
    display: none;
  }
}
/* ======= Slider style (Inner Page Slider) ======= */
.slider .owl-item {
  height: 100vh;
  position: relative;
}

.slider .item {
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  background-size: cover;
  background-position: center;
}

.slider .item .caption {
  z-index: 9;
}

.slider .owl-theme .owl-dots {
  position: absolute;
  width: 100%;
  bottom: 3%;
  right: 12%;
  text-align: right;
}

.slider .owl-theme .owl-dots .owl-dot span {
  width: 13px;
  height: 13px;
  margin: 0 3px;
  border-radius: 50%;
  background: transparent;
  border: 1px solid rgba(255, 255, 255, 0.5);
}

.slider .owl-theme .owl-dots .owl-dot.active span,
.slider .owl-theme .owl-dots .owl-dot:hover span {
  background: #1ac4ff;
  border: 1px solid #1ac4ff;
}

/* bounce */
.header .arrow {
  position: absolute;
  bottom: 40px;
  width: 100%;
  text-align: center;
  z-index: 8;
}

.header .arrow.bounce {
  -webkit-animation: bounce 2s infinite;
  animation: bounce 2s infinite;
}

.header .arrow i {
  position: relative;
  display: inline-block;
  width: 50px;
  height: 50px;
  line-height: 50px;
  color: #fff;
  font-size: 15px;
  border: 1px solid rgba(255, 255, 255, 0.3);
  border-radius: 100%;
}

.header .arrow i:hover {
  border: 1px solid #1ac4ff;
}

.header .arrow i:hover:after {
  -webkit-transform: scale(1.2);
  transform: scale(1.2);
  opacity: 0;
  -webkit-transition: all 0.5s;
  transition: all 0.5s;
}

/* ===== Calendar ===== */
.calendar {
  background-color: transparent;
  padding: 35px;
}

.calendar-header {
  background: rgba(128, 137, 254, 0.7);
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-weight: 700;
  color: #ffffff;
  padding: 10px;
}

.calendar-body {
  padding: 10px;
}

.calendar-week-days {
  display: grid;
  grid-template-columns: repeat(7, 1fr);
  font-weight: 600;
  cursor: pointer;
  color: #686868;
}

.calendar-week-days div:hover {
  color: black;
  transform: scale(1.2);
  transition: all 0.2s ease-in-out;
}

.calendar-week-days div {
  display: grid;
  place-items: center;
  color: white;
  height: 50px;
}

.calendar-days {
  display: grid;
  grid-template-columns: repeat(7, 1fr);
  gap: 2px;
  color: #0A0921;
  margin-top: -80px;
}

.calendar-days div {
  height: 80px;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  cursor: pointer;
  animation: to-top 1s forwards;
  color: white;
  background-color: rgba(0, 0, 0, 0.1);
  border: 2px dotted rgba(255, 255, 255, 0.3);
}

.month-picker, .calendaryear {
  padding: 5px 10px;
  cursor: pointer;
  font-family: "Barlow Condensed", sans-serif;
  font-size: 40px;
  font-weight: 100;
  text-transform: uppercase;
  letter-spacing: 3px;
  color: #fff;
  margin: 3px;
  transition: all 0.4s;
}

.month-change {
  height: 30px;
  width: 30px;
  margin: 0px 10px;
  cursor: pointer;
}

.month-change:hover {
  background-color: #9796f0;
  transition: all 0.2s ease-in-out;
  transform: scale(1.12);
}

.year-picker {
  display: flex;
  align-items: center;
  margin-right: 10px;
}

.calendar-days div span {
  position: absolute;
}

.calendar-days div span.numero {
  top: 6px;
  right: 6px;
  font-size: 30px;
  font-weight: 100;
}

.calendar-days div:hover {
  transition: width 0.2s ease-in-out, height 0.2s ease-in-out;
  background-color: rgba(0, 0, 0, 0.3);
  border-radius: 0%;
  color: #f8fbff;
}

.calendar-days div.current-date {
  color: #f8fbff;
  background-color: rgba(151, 150, 240, 0.5);
}

.calendar-days .actividades {
  position: absolute;
  bottom: 0px;
  width: 100%;
  text-align: left;
  height: 30px;
}

.calendar-days .actividad {
  float: left;
  width: 20px;
  height: 20px;
  position: relative;
  margin-left: 6px;
}

.actividad.siembra {
  background: url("../img/icons/siembra.png");
  background-size: contain;
}

.actividad.amanecer {
  background: url("../img/icons/amanecer.png");
  background-size: contain;
}

.actividad.mezcaliza {
  background: url("../img/icons/mezcal.png");
  background-size: contain;
}

.actividad.second {
  right: 10px;
}

.month-list {
  position: relative;
  left: 0;
  top: -130px;
  background-color: #ebebeb;
  color: #151426;
  display: grid;
  grid-template-columns: repeat(3, auto);
  gap: 5px;
  border-radius: 20px;
}

.month-list > div {
  display: grid;
  place-content: center;
  margin: 5px 10px;
  transition: all 0.2s ease-in-out;
}

.month-list > div > div {
  border-radius: 15px;
  padding: 10px;
  cursor: pointer;
}

.month-list > div > div:hover {
  background-color: #9796f0;
  color: #f8fbff;
  transform: scale(0.9);
  transition: all 0.2s ease-in-out;
}

.month-list.show {
  visibility: visible;
  pointer-events: visible;
  transition: 0.6s ease-in-out;
  animation: to-left 0.71s forwards;
}

.month-list.hideonce {
  visibility: hidden;
}

.month-list.hide {
  animation: to-right 1s forwards;
  visibility: none;
  pointer-events: none;
}

.date-time-formate {
  height: 4rem;
  width: 100%;
  font-family: Dubai Light, Century Gothic;
  position: relative;
  display: flex;
  top: 50px;
  justify-content: center;
  align-items: center;
}

.day-text-formate {
  font-family: Microsoft JhengHei UI;
  font-size: 1.4rem;
  padding-right: 5%;
  border-right: 3px solid #9796f0;
}

.date-time-value {
  display: block;
  position: relative;
  text-align: center;
  padding-left: 5%;
}

.time-formate {
  font-size: 1.5rem;
}

.time-formate.hideTime {
  animation: hidetime 1.5s forwards;
}

.day-text-formate.hidetime {
  animation: hidetime 1.5s forwards;
}

.date-formate.hideTime {
  animation: hidetime 1.5s forwards;
}

.day-text-formate.showtime {
  animation: showtime 1s forwards;
}

.time-formate.showtime {
  animation: showtime 1s forwards;
}

.date-formate.showtime {
  animation: showtime 1s forwards;
}

.leyenda .icono {
  max-width: 36px;
}

@keyframes to-top {
  0% {
    transform: translateY(0);
    opacity: 0;
  }
  100% {
    transform: translateY(100%);
    opacity: 1;
  }
}
@keyframes to-left {
  0% {
    transform: translatex(230%);
    opacity: 1;
  }
  100% {
    transform: translatex(0);
    opacity: 1;
  }
}
@keyframes to-right {
  10% {
    transform: translatex(0);
    opacity: 1;
  }
  100% {
    transform: translatex(-150%);
    opacity: 1;
  }
}
@keyframes showtime {
  0% {
    transform: translatex(250%);
    opacity: 1;
  }
  100% {
    transform: translatex(0%);
    opacity: 1;
  }
}
@keyframes hidetime {
  0% {
    transform: translatex(0%);
    opacity: 1;
  }
  100% {
    transform: translatex(-370%);
    opacity: 1;
  }
}
@media screen and (max-width: 768px) {
  .calendar {
    padding: 0px;
  }

  .calendar-days {
    gap: 0px;
    margin-top: -60px;
  }

  .calendar-days div {
    height: 60px;
    border: 1px solid rgba(255, 255, 255, 0.2);
  }

  .calendar-days div span.numero {
    top: 3px;
    right: 3px;
    font-size: 17px;
  }

  .calendar-days .actividades {
    height: 18px;
  }

  .calendar-days .actividad {
    width: 14px;
    height: 14px;
    margin-left: 3px;
  }
}
/* ===== Kenburns Slider style ===== */
.kenburns-section {
  z-index: 1;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  overflow: hidden;
  min-height: 100vh;
  position: relative;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  background-size: cover;
  background-position: center center;
  background-repeat: no-repeat;
}

.kenburns-section::after {
  content: "";
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  z-index: -5;
  background: #000;
}

.kenburns-inner {
  position: relative;
  z-index: 15;
}

.kenburns-inner .caption {
  position: relative;
}

.kenburns-inner .caption h4 {
  font-family: "Barlow Condensed", serif;
  font-size: 15px;
  font-weight: 400;
  text-transform: uppercase;
  letter-spacing: 6px;
  color: #fff;
  margin-bottom: 20px;
  margin-top: 20px;
  -webkit-animation-delay: 0.4s;
  animation-delay: 0.4s;
}

.kenburns-inner .caption h3 {
  position: relative;
  margin-bottom: 20px;
  font-family: "Gilda Display", serif;
  font-size: 48px;
  text-transform: uppercase;
  line-height: 1.35em;
  -webkit-animation-delay: 0.6s;
  animation-delay: 0.6s;
  color: #fff;
  font-weight: 400;
  letter-spacing: 5px;
}

.kenburns-inner .caption h1 {
  position: relative;
  margin-bottom: 20px;
  font-family: "Gilda Display", serif;
  font-size: 55px;
  text-transform: uppercase;
  line-height: 1.35em;
  -webkit-animation-delay: 0.6s;
  animation-delay: 0.6s;
  color: #fff;
  font-weight: 400;
  letter-spacing: 15px;
}

@media screen and (max-width: 767px) {
  .kenburns-inner .caption h4 {
    font-size: 12px;
  }

  .kenburns-inner .caption h1 {
    font-size: 30px;
    margin-bottom: 15px;
    letter-spacing: 5px;
    line-height: 1.35em;
  }

  .kenburns-inner .caption h3 {
    font-size: 30px;
    margin-bottom: 15px;
    letter-spacing: 5px;
    line-height: 1.35em;
  }

  .kenburns-inner .caption {
    text-align: center;
  }
}
/* reservation field for Kenburns slider */
.kenburns-section div.reservation {
  -webkit-writing-mode: vertical-lr;
  -ms-writing-mode: tb-lr;
  writing-mode: vertical-lr;
  -webkit-transform: rotate(180deg);
  -ms-transform: rotate(180deg);
  transform: rotate(180deg);
  text-orientation: sideways;
  text-orientation: sideways;
  margin: 0;
  position: absolute;
  top: 0;
  left: 40px;
  height: 60%;
  z-index: 9;
}

.kenburns-section div.reservation .call {
  color: #fff;
  text-transform: uppercase;
  font-family: "Barlow Condensed", sans-serif;
  font-weight: 400;
  letter-spacing: 5px;
  font-size: 12px;
  line-height: 1.75em;
  margin-top: 15px;
}

.kenburns-section div.reservation .call span {
  font-family: "Gilda Display", serif;
  font-size: 24px;
  color: #1ac4ff;
  letter-spacing: 1px;
}

.kenburns-section div.reservation .icon {
  position: absolute;
  left: -1px;
  top: -50px;
  width: 50px;
  height: 50px;
  border: 1px solid rgba(255, 255, 255, 0.5);
  -webkit-animation: pulse 2s infinite;
  animation: pulse 2s infinite;
  border-radius: 100%;
}

.kenburns-section div.reservation a .icon i {
  color: #FFF;
  font-size: 24px;
  transform: rotate(90deg);
}

.kenburns-section div.reservation a .icon:hover {
  border: 1px solid #1ac4ff;
}

/* bounce */
.kenburns-section .arrow {
  position: absolute;
  bottom: 40px;
  width: 100%;
  text-align: center;
  z-index: 80;
}

.kenburns-section .arrow.bounce {
  -webkit-animation: bounce 2s infinite;
  animation: bounce 2s infinite;
}

.kenburns-section .arrow i {
  position: relative;
  display: inline-block;
  width: 50px;
  height: 50px;
  line-height: 50px;
  color: #fff;
  font-size: 15px;
  border: 1px solid rgba(255, 255, 255, 0.3);
  border-radius: 100%;
}

.kenburns-section .arrow i:hover {
  border: 1px solid #1ac4ff;
}

.kenburns-section .arrow i:hover:after {
  -webkit-transform: scale(1.2);
  transform: scale(1.2);
  opacity: 0;
  -webkit-transition: all 0.5s;
  transition: all 0.5s;
}

@media screen and (max-width: 768px) {
  .kenburns-section div.reservation {
    display: none;
  }
}
/* ======= Slider Grid Background ======= */
.slider-grid-bg {
  min-height: 100vh;
  background-size: cover;
  background-position: 50%;
  padding-top: 210px;
  padding-bottom: 60px;
  position: relative;
  overflow: hidden;
  background: #fff;
  color: #222;
}

.slider-grid-bg .grid-img {
  height: 100%;
  width: 100%;
  background-size: cover;
  background-position: center;
  position: absolute;
  left: 0;
  top: 0;
  z-index: 1;
  transform: scale(1);
  transition: transform 5s, opacity 0.35s;
  opacity: 0;
  display: block;
}

.slider-grid-bg .grid-img-active {
  opacity: 1;
  transform: scale(1.03);
  -webkit-filter: brightness(70%);
}

.slider-grid-bg:hover .grid-img-active {
  -webkit-filter: brightness(70%);
}

.slider-grid-bg .content {
  position: relative;
  z-index: 2;
}

.slider-grid-bg .grid-item {
  margin-bottom: 30px;
  display: -ms-flexbox;
  display: flex;
}

.slider-grid-bg .grid-con {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-align: end;
  align-items: flex-end;
  border: 1px solid rgba(255, 255, 255, 0.4);
  width: 100%;
  text-decoration: none;
  color: currentColor;
  padding: 80px 30px 30px;
  transition: all 0.3s;
  background: transparent;
  min-height: 340px;
  position: relative;
}

.slider-grid-bg .grid-con-active,
.slider-grid-bg .grid-con:hover {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-align: end;
  align-items: flex-end;
  width: 100%;
  text-decoration: none;
  color: currentColor;
  padding: 80px 30px 30px;
  transition: all 0.3s;
  background: transparent;
  min-height: 340px;
  position: relative;
  background: #fff;
  border: 1px solid #fff;
}

.slider-grid-bg .book {
  position: absolute;
  top: 20px;
  right: 20px;
  z-index: 3;
  writing-mode: vertical-rl;
  -ms-transform: rotate(180deg);
  -o-transform: rotate(180deg);
  -moz-transform: rotate(180deg);
  -webkit-transform: rotate(180deg);
  transform: rotate(180deg);
  text-orientation: mixed;
  text-transform: uppercase;
  color: #fff;
  font-size: 15px;
  font-weight: 400;
  letter-spacing: 3px;
  background: transparent;
  border: 1px solid rgba(255, 255, 255, 0.5);
  font-family: "Barlow Condensed", sans-serif;
  padding: 10px 3px;
  line-height: 1.5em;
}

.slider-grid-bg .grid-con:hover .book {
  color: #222;
  border: 1px solid #ddd;
}

.slider-grid-bg .grid-con-active .book {
  color: #222;
  border: 1px solid #ddd;
}

.slider-grid-bg .subtitle {
  display: block;
  font-weight: 400;
  color: #fff;
  margin-bottom: 5px;
  letter-spacing: 3px;
  text-transform: uppercase;
  font-size: 15px;
  font-family: "Barlow Condensed", sans-serif;
  display: inline;
}

.slider-grid-bg .grid-con:hover .subtitle {
  color: #1ac4ff;
}

.slider-grid-bg .grid-con-active .subtitle {
  color: #1ac4ff;
}

.slider-grid-bg .title {
  display: block;
  font-family: "Gilda Display", serif;
  font-size: 27px;
  line-height: 1.25em;
  letter-spacing: 0px;
  color: #fff;
  margin-top: 5px;
  margin-bottom: 0px;
}

.slider-grid-bg .grid-con:hover .title {
  color: #222;
}

.slider-grid-bg .grid-con-active .title {
  color: #222;
}

/* bounce */
.slider-grid-bg .arrow {
  position: absolute;
  bottom: 40px;
  width: 100%;
  text-align: center;
  z-index: 80;
}

.slider-grid-bg .arrow.bounce {
  -webkit-animation: bounce 2s infinite;
  animation: bounce 2s infinite;
}

.slider-grid-bg .arrow i {
  position: relative;
  display: inline-block;
  width: 50px;
  height: 50px;
  line-height: 50px;
  color: #fff;
  font-size: 15px;
  border: 1px solid rgba(255, 255, 255, 0.3);
  border-radius: 100%;
}

.slider-grid-bg .arrow i:hover {
  border: 1px solid #1ac4ff;
}

.slider-grid-bg .arrow i:hover:after {
  -webkit-transform: scale(1.2);
  transform: scale(1.2);
  opacity: 0;
  -webkit-transition: all 0.5s;
  transition: all 0.5s;
}

@media only screen and (max-width: 1199px) {
  .slider-grid-bg .grid-con {
    min-height: 300px;
  }

  .slider-grid-bg .grid-con-active,
.slider-grid-bg .grid-con:hover {
    min-height: 300px;
  }
}
@media only screen and (max-width: 767px) {
  .slider-grid-bg .grid-con {
    min-height: 240px;
  }

  .slider-grid-bg .grid-con-active,
.slider-grid-bg .grid-con:hover {
    min-height: 240px;
  }
}
/* ======= Page Banner Header style ======= */
.banner-header {
  height: 60vh;
}

.banner-header.full-height {
  min-height: 100vh;
  background-position: center;
}

.banner-header.full-height h4 {
  font-family: "Barlow Condensed", serif;
  font-size: 15px;
  font-weight: 400;
  text-transform: uppercase;
  letter-spacing: 6px;
  color: #fff;
  margin-bottom: 20px;
  margin-top: 20px;
}

.banner-header.full-height h1 {
  position: relative;
  margin-bottom: 20px;
  font-family: "Gilda Display", serif;
  font-size: 55px;
  text-transform: uppercase;
  line-height: 1.35em;
  color: #fff;
  font-weight: 400;
  letter-spacing: 15px;
}

.banner-header h1 {
  font-size: 60px;
  color: #fff;
  position: relative;
}

.banner-header.full-height h3 {
  position: relative;
  margin-bottom: 20px;
  font-family: "Gilda Display", serif;
  font-size: 48px;
  text-transform: uppercase;
  line-height: 1.35em;
  color: #fff;
  font-weight: 400;
  letter-spacing: 7px;
}

.banner-header .caption {
  position: relative;
}

.banner-header h5 {
  color: #fff;
  font-size: 15px;
  font-family: "Barlow Condensed", sans-serif;
  text-transform: uppercase;
  letter-spacing: 6px;
  margin-bottom: 10px;
}

.banner-header h5 a {
  color: #1ac4ff;
}

.banner-header p {
  font-size: 15px;
  margin-bottom: 0;
  color: #fff;
}

.banner-header p a {
  color: #1ac4ff;
}

/* author & date */
.banner-header .author .avatar {
  width: 27px;
  height: 27px;
  overflow: hidden;
  border-radius: 50%;
  margin-right: 5px;
}

.banner-header .post {
  margin-top: 20px;
}

.banner-header .post div {
  display: inline-block;
}

.banner-header .post .author {
  margin-right: 20px;
  font-size: 13px;
}

.banner-header .post .date-comment {
  position: relative;
  font-size: 13px;
  color: #fff;
  margin-right: 20px;
}

.banner-header .post .date-comment i {
  color: #1ac4ff;
  font-size: 18px;
  margin-right: 5px;
}

.banner-header .post .date-comment:last-of-type {
  margin-right: 0px;
}

/* bounce */
.banner-header .arrow {
  position: absolute;
  bottom: 40px;
  width: 100%;
  text-align: center;
  z-index: 8;
}

.banner-header .arrow.bounce {
  -webkit-animation: bounce 2s infinite;
  animation: bounce 2s infinite;
}

.banner-header .arrow i {
  position: relative;
  display: inline-block;
  width: 50px;
  height: 50px;
  line-height: 50px;
  color: #fff;
  font-size: 15px;
  border: 1px solid rgba(255, 255, 255, 0.3);
  border-radius: 100%;
}

.banner-header .arrow i:hover {
  border: 1px solid #fff;
}

.banner-header .arrow i:hover:after {
  -webkit-transform: scale(1.2);
  transform: scale(1.2);
  opacity: 0;
  -webkit-transition: all 0.5s;
  transition: all 0.5s;
}

@media screen and (max-width: 767px) {
  .banner-header.full-height h1 {
    font-size: 30px;
    margin-bottom: 15px;
    letter-spacing: 10px;
    line-height: 1.35em;
  }

  .banner-header.full-height h3 {
    font-size: 30px;
    margin-bottom: 15px;
    letter-spacing: 10px;
    line-height: 1.35em;
  }

  .banner-header.full-height h4 {
    font-size: 12px;
  }

  .banner-header h1 {
    font-size: 45px;
  }
}
/* =======  Video Background  ======= */
.video-fullscreen-wrap {
  height: 100vh;
  overflow: hidden;
}

.video-fullscreen-wrap .caption {
  position: relative;
}

.video-fullscreen-wrap .caption.textleft {
  text-align: left;
}

.video-fullscreen-wrap h3 {
  position: relative;
  font-family: "Libre Caslon Display", serif;
  font-size: 60px;
  line-height: 1em;
  color: #fff;
  font-weight: 400;
}

.video-fullscreen-wrap h3 span {
  display: block;
  font-size: 30px;
  display: block;
  margin-top: 0px;
  line-height: 1.5em;
  -webkit-animation-delay: 0.3s;
  animation-delay: 0.3s;
  color: #C19D60;
}

.video-fullscreen-video {
  height: 100%;
}

.video-fullscreen-wrap video {
  width: 100vw;
  height: auto;
  min-width: 100%;
  min-height: 100%;
  -o-object-fit: cover;
  object-fit: cover;
}

.video-fullscreen-wrap .video-fullscreen-inner {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: right;
}

.video-lauch-wrapper {
  border: none;
  background: none;
  width: 100%;
  padding: 0;
}

.video-fullscreen-wrap .overlay {
  /*  background-image: linear-gradient(to bottom right, #000000, #000000);*/
  opacity: 1;
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  width: 100%;
  height: 100%;
  margin: 0;
  box-sizing: border-box;
  text-align: center;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  z-index: 4;
}

.video-fullscreen-wrap .butn-dark a {
  color: #fff;
}

.position-relative {
  position: relative !important;
}

/* ======= Team style ======= */
.team .item {
  margin-bottom: 30px;
}

.team .item:hover .info .social {
  top: 0;
}

.team .info {
  text-align: center;
  padding: 30px 20px;
  position: relative;
  overflow: hidden;
  background: #fff;
}

.team .info h6 {
  font-size: 20px;
  margin-bottom: 5px;
  color: #222;
}

.team .info p {
  margin-bottom: 0px;
  color: #666;
}

.team .info .social {
  position: absolute;
  top: 100%;
  left: 0;
  width: 100%;
  height: 100%;
  background: #fff;
  -webkit-transition: all 0.4s;
  transition: all 0.4s;
}

.team .info .social a {
  font-size: 15px;
  color: #1ac4ff;
  margin: 0 5px;
}

.team .info .social a:hover {
  color: #222;
}

.team .info .social p {
  margin-bottom: 0px;
  color: #666;
}

/* ======= Rooms1 style ======= */
.rooms1 .item {
  position: relative;
  overflow: hidden;
  margin-bottom: 30px;
}

.rooms1 .item:hover img {
  -webkit-filter: none;
  filter: none;
  -webkit-transform: scale(1.09, 1.09);
  transform: scale(1.09, 1.09);
  -webkit-filter: brightness(70%);
  -webkit-transition: all 1s ease;
  -moz-transition: all 1s ease;
  -o-transition: all 1s ease;
  -ms-transition: all 1s ease;
  transition: all 1s ease;
}

.rooms1 .item:hover .con {
  bottom: 0;
}

.rooms1 .item img {
  -webkit-transition: all 0.5s;
  transition: all 0.5s;
}

.rooms1 .item .category {
  position: absolute;
  top: 20px;
  right: 20px;
  z-index: 3;
  writing-mode: vertical-rl;
  -ms-transform: rotate(180deg);
  -o-transform: rotate(180deg);
  -moz-transform: rotate(180deg);
  -webkit-transform: rotate(180deg);
  transform: rotate(180deg);
  text-orientation: mixed;
  text-transform: uppercase;
  color: #fff;
  font-size: 15px;
  font-weight: 400;
  letter-spacing: 3px;
  background: transparent;
  border: 1px solid rgba(255, 255, 255, 0.5);
  font-family: "Barlow Condensed", sans-serif;
  padding: 10px 3px;
  line-height: 1.5em;
}

.rooms1 .item .category a {
  color: #fff;
}

.rooms1 .item .category:hover {
  color: #fff;
}

.rooms1 .item .con {
  padding: 20px;
  position: absolute;
  bottom: -60px;
  left: 0;
  width: 100%;
  -webkit-transition: all 0.3s;
  transition: all 0.3s;
  text-align: left;
  z-index: 20;
  height: auto;
  box-sizing: border-box;
  background: -moz-linear-gradient(top, transparent 0, rgba(0, 0, 0, 0.1) 2%, rgba(0, 0, 0, 0.75) 90%);
  background: -webkit-linear-gradient(top, transparent 0, rgba(0, 0, 0, 0.01) 2%, rgba(0, 0, 0, 0.75) 90%);
  background: linear-gradient(to bottom, transparent 0, rgba(0, 0, 0, 0.01) 2%, rgba(0, 0, 0, 0.75) 90%);
}

.rooms1 .item .con h6,
.rooms1 .item .con h6 a {
  position: relative;
  color: #fff;
  font-size: 15px;
  font-family: "Barlow Condensed", sans-serif;
  display: inline;
  letter-spacing: 3px;
  text-transform: uppercase;
}

.rooms1 .item .con h6 i {
  font-size: 6px;
  margin-right: 2px;
}

.rooms1 .item .con h5,
.rooms1 .item .con h5 a {
  position: relative;
  color: #fff;
  font-size: 27px;
  font-weight: 400;
  margin-top: 5px;
  margin-bottom: 5px;
}

.rooms1 .item .con p {
  color: #fff;
  font-size: 20px;
}

.rooms1 .item .con i {
  color: #fff;
  font-size: 18px;
}

.rooms1 .item .con i:hover {
  color: #fff;
}

.rooms1 .item .con .butn-light {
  margin-left: 0;
  border-radius: 30px;
  border-color: #666;
  padding: 11px 30px;
}

.rooms1 .item .con .butn-light:hover {
  border-color: #1ac4ff;
}

/* line animation */
.rooms1 .item .line {
  text-align: center;
  height: 1px;
  width: 60px;
  background-color: rgba(255, 255, 255, 0.2);
  transition-property: all;
  transition-duration: 0.3s;
  transition-timing-function: ease-in-out;
  margin: auto 0 30px;
}

.rooms1 .item:hover .line {
  width: 100%;
  transition-property: all;
  transition-duration: 0.8s;
  transition-timing-function: ease-in-out;
}

/* facilities */
.rooms1 .facilities ul {
  margin: 0;
  padding: 0;
  list-style: none;
}

.rooms1 .facilities ul li {
  color: #fff;
  font-size: 10px;
  text-transform: uppercase;
  letter-spacing: 3px;
  line-height: 1.2em;
  padding-bottom: 20px;
  float: left;
}

.rooms1 .facilities ul li i {
  margin-right: 10px;
}

/* permalink */
.rooms1 .item .con .permalink {
  font-family: "Barlow Condensed", sans-serif;
  font-size: 15px;
  letter-spacing: 3px;
  text-transform: uppercase;
  color: #fff;
  height: 0;
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  -ms-transition: all 0.3s ease;
  -o-transition: all 0.3s ease;
  -moz-transition: all 0.3s ease;
  -webkit-transition: all 0.3s ease;
  transition: all 0.3s ease;
  display: inline;
}

.rooms1 .item .con .permalink a {
  color: #fff;
  text-align: right;
}

.rooms1 .item .con .permalink i {
  font-size: 10px;
  text-align: right;
}

.rooms1 .item .con .permalink a:hover,
.rooms1 .item .con .permalink i:hover {
  color: #1ac4ff;
}

/* ======= Cursos  ======= */
.cursos {
  position: relative;
}

.cursos:last-child {
  margin-bottom: 20px;
}

.cursos figure {
  margin: 0;
  position: relative;
  width: 63.666667%;
  margin-left: auto;
}

.cursos.left figure {
  margin-left: 0;
  margin-right: auto;
}

.cursos figure img:hover {
  transform: scale(0.98);
}

.cursos .caption {
  background: #fff;
  position: absolute;
  left: 0;
  top: 50%;
  width: 40%;
  padding: 4% 4% 4% 0;
  -ms-transform: translate(0, -50%);
  -webkit-transform: translate(0, -50%);
  transform: translate(0, -50%);
}

.cursos.left .caption {
  left: auto;
  right: 0;
  background: #fff;
  padding: 4% 0 4% 4%;
}

.cursos .caption.padding-left {
  padding-left: 4%;
}

.cursos.left .caption.padding-left {
  padding-right: 4%;
}

.cursos .caption h4,
.cursos .caption h4 a {
  font-size: 35px;
  color: #222;
  margin-bottom: 5px;
}

.cursos .caption h3,
.cursos .caption h3 a {
  font-size: 24px;
  color: #1ac4ff;
  margin-bottom: 0px;
}

.cursos .caption h3 span {
  font-size: 15px;
  color: #666;
  font-family: "Barlow", sans-serif;
}

.cursos .caption h6 {
  display: inline-block;
  color: #666;
  font-family: "Barlow", sans-serif;
  font-weight: 400;
  font-size: 10px;
  margin-bottom: 10px;
  letter-spacing: 5px;
  text-transform: uppercase;
  -webkit-align-self: flex-start;
  -ms-flex-item-align: start;
  align-self: flex-start;
}

.cursos .caption .info-wrapper {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
  -webkit-justify-content: space-between;
  -ms-flex-pack: justify;
  justify-content: space-between;
  -webkit-flex-wrap: wrap;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
}

.cursos .caption .info-wrapper .more {
  color: #666;
  text-decoration: none;
  font-size: 14px;
}

.cursos .caption .info-wrapper .more i {
  color: #666;
  font-size: 10px;
}

.cursos .caption .info-wrapper .date {
  color: #222;
  font-family: "Barlow Condensed", sans-serif;
  font-size: 15px;
  background: transparent;
  border: 1px solid #1ac4ff;
  text-transform: uppercase;
  letter-spacing: 3px;
  padding: 3px 12px;
}

.cursos .caption .info-wrapper .date:hover {
  background: transparent;
  border: 1px solid #222;
  color: #222;
}

.cursos .curso-inactivo {
  opacity: 0.6;
}

.detalles-curso ol,
.detalles-curso ul {
  padding: inherit;
  margin: 0;
}

.detalles-curso ol li {
  list-style-type: decimal;
  list-style: auto;
}

.detalles-curso ul li {
  list-style: square;
}

.banderas .bandera {
  display: inline-block;
  max-width: 40px;
  border-radius: 3px;
}

.detalles-curso .curso-gift {
  max-width: 50px;
}

@media (max-width: 991.98px) {
  .cursos figure {
    width: auto;
    margin: 0;
  }

  .cursos .caption {
    position: relative;
    left: 0;
    top: 0;
    width: auto;
    padding: 30px 20px;
    -ms-transform: translate(0, 0);
    -webkit-transform: translate(0, 0);
    transform: translate(0, 0);
  }
}
/* hr style */
hr {
  margin-top: 5px;
  margin-bottom: 20px;
  border: 0;
}

hr.border-1 {
  border-top: 1px solid #ececec;
}

hr.border-2 {
  border-top: 1px solid #ececec;
}

/* button link */
.link-btn {
  display: inline-block;
  position: relative;
  text-transform: uppercase;
  font-family: "Barlow Condensed", sans-serif;
  font-size: 15px;
  font-weight: 400;
  letter-spacing: 3px;
}

.link-btn i {
  margin-left: 3px;
  font-size: 15px;
  color: #1ac4ff;
}

.link-btn:before {
  content: "";
  position: absolute;
  left: 0;
  bottom: 0;
  width: 0;
  height: 1px;
  background-color: #1ac4ff;
  -webkit-transition: all ease 0.4s;
  transition: all ease 0.4s;
}

.link-btn:hover::before {
  width: 100%;
}

/* reservation button */
.reservations {
  display: flex;
  align-items: center;
}

.reservations .icon {
  position: relative;
  top: 3px;
}

.reservations .icon span {
  font-size: 35px;
  color: #1ac4ff;
}

.reservations .icon.color-1 span {
  font-size: 40px;
  color: #fff;
}

.reservations .text {
  margin-left: 20px;
}

.reservations .text p {
  font-size: 15px;
  margin: 0;
}

.reservations .text a {
  font-size: 24px;
  color: #1ac4ff;
  font-family: "Barlow Condensed", sans-serif;
  letter-spacing: 1px;
}

.reservations .text a.color-1 {
  color: #fff;
}

/* ======= Rooms 3 ======= */
.rooms3 {
  color: #666;
}

.rooms3 .square-flip {
  -webkit-perspective: 1000;
  -moz-perspective: 1000;
  -ms-perspective: 1000;
  perspective: 1000;
  -webkit-transform: perspective(1000px);
  -moz-transform: perspective(1000px);
  -ms-transform: perspective(1000px);
  transform: perspective(1000px);
  -webkit-transform-style: preserve-3d;
  -moz-transform-style: preserve-3d;
  -ms-transform-style: preserve-3d;
  transform-style: preserve-3d;
  position: relative;
  background-size: cover;
  background-position: center center;
}

.rooms3 .square-flip {
  min-height: 450px;
  margin-bottom: 30px;
}

.rooms3 .square,
.rooms3 .square2 {
  width: 100%;
  height: 100%;
}

.rooms3 .square {
  background-size: cover;
  background-position: center center;
  -webkit-transition: -webkit-transform 0.9s cubic-bezier(0.68, -0.55, 0.265, 1.25);
  transition: -webkit-transform 0.9s cubic-bezier(0.68, -0.55, 0.265, 1.25);
  -o-transition: transform 0.9s cubic-bezier(0.68, -0.55, 0.265, 1.25);
  transition: transform 0.9s cubic-bezier(0.68, -0.55, 0.265, 1.25);
  transition: transform 0.9s cubic-bezier(0.68, -0.55, 0.265, 1.25), -webkit-transform 0.9s cubic-bezier(0.68, -0.55, 0.265, 1.25);
  overflow: hidden;
  position: absolute;
  top: 0;
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
}

.rooms3 .square .category {
  position: absolute;
  top: 20px;
  left: 20px;
  z-index: 3;
  writing-mode: vertical-rl;
  -ms-transform: rotate(180deg);
  -o-transform: rotate(180deg);
  -moz-transform: rotate(180deg);
  -webkit-transform: rotate(180deg);
  transform: rotate(180deg);
  text-orientation: mixed;
  text-transform: uppercase;
  color: #fff;
  font-size: 15px;
  font-weight: 400;
  letter-spacing: 3px;
  background: transparent;
  border: 1px solid rgba(255, 255, 255, 0.5);
  font-family: "Barlow Condensed", sans-serif;
  padding: 10px 3px;
  line-height: 1.5em;
}

.rooms3 .square .category a {
  color: #fff;
}

.rooms3 .square .category:hover {
  color: #fff;
}

.rooms3 .square h4 {
  color: #fff;
  font-size: 27px;
  text-align: left;
  margin-bottom: 0;
  display: inline-flex;
}

.rooms3 .square h6, .rooms3 .square h6 a {
  position: relative;
  color: #fff;
  font-size: 15px;
  font-weight: 400;
  font-family: "Barlow Condensed", sans-serif;
  letter-spacing: 3px;
  text-transform: uppercase;
  margin-bottom: 10px;
}

.rooms3 .square-flip .square {
  -webkit-transform: rotateY(0deg);
  transform: rotateY(0deg);
  -webkit-transform-style: preserve-3d;
  transform-style: preserve-3d;
  z-index: 1;
}

.rooms3 .square-flip:hover .square {
  -webkit-transform: rotateY(-180deg);
  transform: rotateY(-180deg);
  -webkit-transform-style: preserve-3d;
  transform-style: preserve-3d;
}

.rooms3 .square2 {
  background: #f8f5f0;
  background-size: cover;
  background-position: center center;
  -webkit-transition: -webkit-transform 0.9s cubic-bezier(0.68, -0.55, 0.265, 1.25);
  transition: -webkit-transform 0.9s cubic-bezier(0.68, -0.55, 0.265, 1.25);
  -o-transition: transform 0.9s cubic-bezier(0.68, -0.55, 0.265, 1.25);
  transition: transform 0.9s cubic-bezier(0.68, -0.55, 0.265, 1.25);
  transition: transform 0.9s cubic-bezier(0.68, -0.55, 0.265, 1.25), -webkit-transform 0.9s cubic-bezier(0.68, -0.55, 0.265, 1.25);
  overflow: hidden;
  position: absolute;
  top: 0;
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
}

.rooms3 .square2.bg-white {
  background: #fff;
}

.rooms3 .square2:before {
  top: 25px;
  left: 15px;
  right: 15px;
  bottom: 25px;
}

.rooms3 .square2 h4 {
  font-size: 30px;
  color: #222;
}

.rooms3 .square2 h6 {
  position: relative;
  color: #1ac4ff;
  font-size: 15px;
  font-weight: 400;
  font-family: "Barlow Condensed", sans-serif;
  letter-spacing: 3px;
  text-transform: uppercase;
  margin-bottom: 10px;
}

.rooms3 .square2 p {
  font-size: 15px;
  color: #666;
}

.rooms3 .square2 .icon img {
  width: 60px;
  color: #666;
  margin-bottom: 20px;
}

.rooms3 .btn-line a {
  border-bottom: 1px solid #ccc;
  color: #666;
}

.rooms3 .btn-line a:hover {
  border-bottom: 1px solid #1ac4ff;
  color: #666;
}

.rooms3 .square-flip .square2 {
  -webkit-transform: rotateY(180deg);
  transform: rotateY(180deg);
  -webkit-transform-style: preserve-3d;
  transform-style: preserve-3d;
  z-index: 1;
}

.rooms3 .square-flip:hover .square2 {
  -webkit-transform: rotateY(0deg);
  transform: rotateY(0deg);
  -webkit-transform-style: preserve-3d;
  transform-style: preserve-3d;
}

.rooms3 .square-container {
  padding: 40px;
  text-align: right;
  height: 450px;
  position: relative;
  -webkit-transition: -webkit-transform 0.9s cubic-bezier(0.68, -0.55, 0.265, 1.25);
  transition: -webkit-transform 0.9s cubic-bezier(0.68, -0.55, 0.265, 1.25);
  -o-transition: transform 0.9s cubic-bezier(0.68, -0.55, 0.265, 1.25);
  transition: transform 0.9s cubic-bezier(0.68, -0.55, 0.265, 1.25);
  transition: transform 0.9s cubic-bezier(0.68, -0.55, 0.265, 1.25), -webkit-transform 0.9s cubic-bezier(0.68, -0.55, 0.265, 1.25);
  -webkit-transform: translateX(0px) scale(1);
  -ms-transform: translateX(0px) scale(1);
  transform: translateX(0px) scale(1);
  -webkit-transform-style: preserve-3d;
  transform-style: preserve-3d;
  z-index: 2;
}

.rooms3 .square-container .box-title {
  /*	width: 100%;*/
}

.rooms3 .square-flip:hover .square-container {
  -webkit-transform: translateX(-650px) scale(0.88);
  -ms-transform: translateX(-650px) scale(0.88);
  transform: translateX(-650px) scale(0.88);
}

.rooms3 .square-container2 {
  padding: 30px;
  text-align: left;
  position: relative;
  top: 50%;
  -webkit-transition: -webkit-transform 0.9s cubic-bezier(0.68, -0.55, 0.265, 1.25);
  transition: -webkit-transform 0.9s cubic-bezier(0.68, -0.55, 0.265, 1.25);
  -o-transition: transform 0.9s cubic-bezier(0.68, -0.55, 0.265, 1.25);
  transition: transform 0.9s cubic-bezier(0.68, -0.55, 0.265, 1.25);
  transition: transform 0.9s cubic-bezier(0.68, -0.55, 0.265, 1.25), -webkit-transform 0.9s cubic-bezier(0.68, -0.55, 0.265, 1.25);
  -webkit-transform: translateY(-50%) translateX(650px) translateZ(60px) scale(0.88);
  transform: translateY(-50%) translateX(650px) translateZ(60px) scale(0.88);
  -webkit-transform-style: preserve-3d;
  transform-style: preserve-3d;
  z-index: 2;
}

.rooms3 .square-flip:hover .square-container2 {
  -webkit-transform: translateY(-50%) translateX(0px) translateZ(0px) scale(1);
  transform: translateY(-50%) translateX(0px) translateZ(0px) scale(1);
}

.rooms3 .flip-overlay {
  display: block;
  background: #000;
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  opacity: 0.2;
}

@media screen and (max-width: 991px) {
  .rooms3 .square-flip {
    margin-bottom: 30px;
  }

  .rooms3 {
    padding-bottom: 70px;
  }
}
/* ======= Rooms Page style ======= */
.rooms-page h6 {
  position: relative;
  font-family: "Gilda Display", serif;
  font-size: 24px;
  color: #222;
}

/* page list */
.page-list {
  position: relative;
  display: block;
}

.page-list li {
  position: relative;
  display: flex;
  align-items: center;
}

.page-list li + li {
  margin-top: 10px;
}

.page-list-icon {
  display: flex;
  align-items: center;
}

.page-list-icon span {
  font-size: 18px;
  color: #1ac4ff;
}

.page-list-text {
  margin-left: 15px;
}

.page-list-text p {
  font-size: 15px;
  margin: 0;
}

/* owl-nav next and prev */
.rooms-page .owl-nav {
  position: absolute;
  bottom: 3%;
  left: 12%;
}

.rooms-page .owl-prev {
  float: left;
}

.rooms-page .owl-next {
  float: right;
}

.rooms-page .owl-theme .owl-nav [class*=owl-] {
  width: 40px;
  height: 40px;
  line-height: 34px;
  background: transparent;
  border-radius: 100%;
  color: rgba(255, 255, 255, 0.75);
  font-size: 11px;
  margin-right: 3px;
  cursor: pointer;
  border: 1px solid rgba(255, 255, 255, 0.3);
  transition: all 0.2s ease-in-out;
  transform: scale(1);
}

.rooms-page .owl-theme .owl-nav [class*=owl-]:hover {
  transform: scale(0.9);
  background: transparent;
  border: 1px solid #1ac4ff;
  color: #1ac4ff;
}

@media screen and (max-width: 768px) {
  .rooms-page .owl-nav {
    display: none;
  }
}
/* ======= Facilties style ======= */
.facilties .single-facility {
  background: transparent;
  padding: 30px 20px;
  -webkit-transition: 0.5s;
  transition: 0.5s;
  position: relative;
  z-index: 1;
  margin-bottom: 30px;
  line-height: 1;
  border: 1px solid #f1eeeb;
}

.facilties .single-facility h5 {
  font-size: 24px;
  color: #222;
  margin-bottom: 10px;
}

.facilties .single-facility:hover h5 {
  color: #222;
  -webkit-transition: 0.5s;
  transition: 0.5s;
}

/*
.facilties .facility-shape:hover span {
  -webkit-transform: rotate(360deg);
          transform: rotate(360deg);
}
*/
.facilties .single-facility span {
  font-size: 45px;
  color: #1ac4ff;
  line-height: 1;
  margin-bottom: 20px;
  display: inline-block;
  -webkit-transition: 0.5s;
  transition: 0.5s;
}

.facilties .single-facility p {
  margin: 0;
  color: #666;
}

.facilties .single-facility .facility-shape {
  position: absolute;
  opacity: 0.02;
  z-index: -1;
  bottom: 15px;
  left: 120px;
}

.facilties .single-facility .facility-shape span {
  font-size: 140px;
  color: #222;
}

/* hr */
hr {
  margin-top: 5px;
  margin-bottom: 20px;
  border: 0;
  border-top: 1px solid rgba(255, 255, 255, 0.25);
}

/* room facilities */
.room-facilities .wrap {
  background: #222;
  padding: 30px;
}

.room-facilities ul {
  margin: 0;
  padding: 0;
  list-style: none;
}

.room-facilities ul li {
  color: #666;
  font-size: 15px;
  padding-bottom: 10px;
}

.room-facilities ul li i {
  margin-right: 10px;
  color: #1ac4ff;
  font-size: 20px;
}

.room-facilities ul span {
  font-weight: bold;
}

.bold-text {
  font-weight: bold;
}

/* ======= Services style ======= */
.services .img {
  position: relative;
  overflow: hidden;
}

.services .img:hover img {
  -webkit-filter: none;
  filter: none;
  -webkit-transform: scale(1.09, 1.09);
  transform: scale(1.09, 1.09);
  -webkit-transition: all 1s ease;
  -moz-transition: all 1s ease;
  -o-transition: all 1s ease;
  -ms-transition: all 1s ease;
  transition: all 1s ease;
}

.services .img img {
  -webkit-transition: all 0.5s;
  transition: all 0.5s;
}

.services .content {
  width: 100%;
  padding: 30px;
}

.services .content .date {
  display: table-cell;
  position: relative;
  width: 80px;
  z-index: 2;
}

.services .content .date h3 {
  font-size: 65px;
  font-weight: 500;
  color: #1ac4ff;
  margin-bottom: 0px;
}

.services .content .date h6 {
  color: #666;
  letter-spacing: 2px;
  font-size: 10px;
  font-family: "Barlow", sans-serif;
  text-transform: uppercase;
  font-weight: 400;
}

.services .content .cont {
  display: table-cell;
  vertical-align: middle;
}

.services .content .cont h4 {
  margin: 0 0 10px 0;
  font-size: 32px;
  color: #222;
  font-weight: 400;
  line-height: 1.5em;
}

.services .content .cont .more {
  font-size: 12px;
  font-weight: 400;
  letter-spacing: 2px;
  text-transform: uppercase;
  color: #1ac4ff;
  padding-left: 40px;
  position: relative;
}

.services .content .cont .more:after {
  content: "";
  width: 30px;
  height: 1px;
  background: #1ac4ff;
  position: absolute;
  top: 50%;
  left: 0px;
}

.services .content .cont .more:hover {
  color: #222;
}

.services .content .info h6 {
  font-size: 15px;
  letter-spacing: 3px;
  color: #666;
  font-family: "Barlow Condensed", sans-serif;
  text-transform: uppercase;
  margin-bottom: 5px;
}

.services .content .info .tags {
  color: #1ac4ff;
}

@media screen and (max-width: 991px) {
  .services .content .cont h4 {
    line-height: 1.25em;
  }

  .order2 {
    -webkit-box-ordinal-group: 3 !important;
    -ms-flex-order: 2 !important;
    order: 2 !important;
  }

  .order1 {
    -webkit-box-ordinal-group: 2 !important;
    -ms-flex-order: 1 !important;
    order: 1 !important;
  }
}
@media screen and (max-width: 480px) {
  .services .content .date {
    width: 60px;
  }

  .services .content .info .tags {
    margin: 5px 0 0;
  }

  .services .content .date h3 {
    font-size: 40px;
  }
}
/* =======  Restaurant Menu style ======= */
.restaurant-menu .owl-carousel .owl-stage-outer {
  padding: 20px 0 60px 0;
}

.restaurant-menu .owl-theme .owl-nav [class*=owl-] {
  position: absolute;
  top: 40%;
  background: transparent;
  color: #fff;
  width: 40px;
  height: 40px;
  line-height: 30px;
  border-radius: 50%;
  border: 1px solid #1ac4ff;
  opacity: 0;
  -webkit-transition: all 0.5s;
  transition: all 0.5s;
}

.restaurant-menu .owl-theme .owl-nav [class*=owl-]:hover {
  background: transparent;
  color: #1ac4ff;
}

.restaurant-menu .bg-img .owl-theme .owl-nav [class*=owl-] {
  color: #fff;
}

.restaurant-menu .owl-carousel .owl-nav .owl-next {
  right: 0;
}

.restaurant-menu .owl-carousel .owl-nav .owl-prev {
  left: 0;
}

.restaurant-menu .owl-carousel .owl-nav .owl-next span,
.restaurant-menu .owl-carousel .owl-nav .owl-prev span {
  font-size: 12px;
}

.restaurant-menu .owl-theme:hover .owl-nav [class*=owl-] {
  opacity: 1;
}

.restaurant-menu .tabs-icon .item {
  padding: 15px 5px;
  cursor: pointer;
}

.restaurant-menu .tabs-icon .item h6 {
  font-size: 20px;
  color: #fff;
  margin-bottom: 0;
}

.restaurant-menu .tabs-icon .owl-item.actived .item,
.restaurant-menu .tabs-icon .item.active {
  color: #fff;
  position: relative;
  background: transparent;
  padding: 15px;
  border: 1px solid #1ac4ff;
}

.restaurant-menu .tabs-icon .owl-item.actived .item .icon,
.restaurant-menu .tabs-icon .item.active .icon {
  color: #1ac4ff;
}

.restaurant-menu .restaurant-menu-content .cont {
  display: none;
}

.restaurant-menu .restaurant-menu-content .cont .restaurant-menu-info {
  text-align: right;
}

.restaurant-menu .restaurant-menu-content .cont h5 {
  font-size: 20px;
  color: #1ac4ff;
  margin-bottom: 10px;
  text-align: left;
  border-bottom: 1px dashed #1ac4ff;
  line-height: 2em;
}

.restaurant-menu .restaurant-menu-content .cont p {
  font-size: 15px;
  color: #adadad;
}

.restaurant-menu .restaurant-menu-content .cont h5 .price {
  color: #adadad;
  float: right;
}

.restaurant-menu .restaurant-menu-content .active {
  display: block;
}

.restaurant-menu .owl-theme .owl-nav [class*=owl-] {
  top: 18%;
}

.restaurant-menu .owl-carousel .owl-nav .owl-next {
  right: -50px;
}

.restaurant-menu .owl-carousel .owl-nav .owl-prev {
  left: -50px;
}

.restaurant-menu .owl-carousel .owl-nav .owl-prev {
  left: -60px;
}

.restaurant-menu .owl-carousel .owl-nav .owl-next {
  right: -60px;
}

.restaurant-menu .restaurant-menu-content .cont .restaurant-menu-info {
  text-align: center;
  margin-bottom: 20px;
}

/* ======= Clients style ======= */
.clients {
  background-color: #fff;
}

.clients .owl-carousel {
  margin: 20px 0;
}

.clients .clients-logo {
  opacity: 1;
  line-height: 0;
}

.clients .clients-logo:hover {
  opacity: 1;
}

.clients img {
  -webkit-filter: none;
  filter: none;
}

.clients img:hover {
  -webkit-filter: grayscale(100%);
  filter: grayscale(100%);
  -webkit-transition: all 0.5s;
  transition: all 0.5s;
}

/* ======= Gallery style ======= */
.gallery-item {
  position: relative;
  padding-top: 20px;
}

.gallery-box {
  overflow: hidden;
  position: relative;
}

.gallery-box .gallery-img {
  position: relative;
  overflow: hidden;
}

.gallery-box .gallery-img:after {
  content: " ";
  display: block;
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  border-radius: 0px;
  transition: all 0.27s cubic-bezier(0.3, 0.1, 0.58, 1);
}

.gallery-box .gallery-img > img {
  transition: all 0.3s cubic-bezier(0.3, 0.1, 0.58, 1);
  border-radius: 0;
}

.gallery-box .gallery-detail {
  opacity: 0;
  color: #FFF;
  width: 100%;
  padding: 20px;
  box-sizing: border-box;
  position: absolute;
  left: 0;
  overflow: hidden;
  transition: all 0.27s cubic-bezier(0.3, 0.1, 0.58, 1);
}

.gallery-box .gallery-detail h4 {
  font-size: 18px;
}

.gallery-box .gallery-detail p {
  color: Rgba(0, 0, 0, 0.4);
  font-size: 14px;
}

.gallery-box .gallery-detail i {
  color: #222;
  padding: 8px;
}

.gallery-box .gallery-detail i:hover {
  color: #222;
}

.gallery-box:hover .gallery-detail {
  top: 50%;
  transform: translate(0, -50%);
  opacity: 1;
}

.gallery-box:hover .gallery-img:after {
  background: rgba(0, 0, 0, 0.2);
}

.gallery-box:hover .gallery-img > img {
  transform: scale(1.05);
}

/* magnific popup custom */
.mfp-figure button {
  border: 0px solid transparent;
}

button.mfp-close,
button.mfp-arrow {
  border: 0px solid transparent;
  background: transparent;
}

/* ======= Promo Video and Testimonials style ======= */
/* play button */
.vid-area h5 {
  font-size: 27px;
  font-weight: 400;
  color: #fff;
  margin-bottom: 30px;
}

.play-button {
  position: relative;
  margin: auto;
  -webkit-transform: scale(1.3);
  -ms-transform: scale(1.3);
  transform: scale(1.3);
  z-index: 4;
}

.play-button svg {
  position: absolute;
  width: 100%;
  height: 100%;
}

.circle-fill circle {
  opacity: 1;
  fill: rgba(174, 140, 100, 0.7);
}

.polygon {
  position: absolute;
  width: 100%;
  height: 100%;
  text-align: center;
  line-height: 90px;
  font-size: 35px;
  z-index: 2;
}

.play-button svg.circle-fill {
  z-index: 1;
  stroke-dasharray: 322;
  stroke-dashoffset: 322;
  -webkit-transition: all 0.5s;
  -o-transition: all 0.5s;
  transition: all 0.5s;
  -webkit-transform: rotate(-90deg);
  -ms-transform: rotate(-90deg);
  transform: rotate(-90deg);
}

.play-button:hover svg.circle-fill {
  stroke-dashoffset: 38.5;
}

.play-button svg.circle-track {
  z-index: 0;
}

.play-button.color {
  color: #1ac4ff;
}

.play-button.color .circle-fill circle {
  fill: #1ac4ff;
}

/* video section */
.video-wrapper {
  min-height: 500px;
}

.video-wrapper .vid {
  position: relative;
  z-index: 8;
}

.video-wrapper .vid .vid-butn:hover .icon {
  color: #fff;
}

.video-wrapper .vid .vid-butn:hover .icon:before {
  -webkit-transform: scale(0);
  -ms-transform: scale(0);
  transform: scale(0);
}

.video-wrapper .vid .vid-butn .icon {
  color: #1ac4ff;
  width: 100px;
  height: 100px;
  border: 1px solid rgba(255, 255, 255, 0.2);
  border-radius: 50%;
  line-height: 100px;
  text-align: center;
  font-size: 40px;
  position: relative;
  -webkit-transition: all 0.5s;
  -o-transition: all 0.5s;
  transition: all 0.5s;
}

.video-wrapper .vid .vid-butn .icon:after {
  content: "";
  position: absolute;
  top: 5px;
  bottom: 5px;
  right: 5px;
  left: 5px;
  border: 1px solid #eee;
  border-radius: 50%;
  z-index: -1;
}

.video-wrapper .vid .vid-butn .icon:before {
  content: "";
  position: absolute;
  top: 5px;
  bottom: 5px;
  right: 5px;
  left: 5px;
  background: #fff;
  border-radius: 50%;
  z-index: -1;
  -webkit-transition: all 0.5s cubic-bezier(1, 0, 0, 1);
  -o-transition: all 0.5s cubic-bezier(1, 0, 0, 1);
  transition: all 0.5s cubic-bezier(1, 0, 0, 1);
}

/* video gallery */
.video-gallery-button {
  position: relative;
  margin: auto;
  -webkit-transform: scale(1.3);
  -ms-transform: scale(1.3);
  transform: scale(1.3);
  z-index: 4;
  margin-left: 15px;
  float: right;
}

.video-gallery-polygon {
  z-index: 2;
  padding-left: 5px;
  display: inline-block;
  position: absolute;
  bottom: 15px;
  right: 15px;
  background: transparent;
  border-radius: 50%;
  border: 1px solid rgba(255, 255, 255, 0.5);
  color: #fff;
  padding: 7px 6px 7px 8px;
  line-height: 0;
}

.video-gallery-polygon:hover {
  background: #fff;
  border: 1px solid #fff;
  color: #1ac4ff;
}

/* testimonials style */
.testimonials-box {
  position: relative;
  background: transparent;
  padding: 30px;
  margin-bottom: 90px;
}

.testimonials-box .head-box h6 {
  font-family: "Barlow Condensed", sans-serif;
  color: #fff;
  font-size: 12px;
  text-transform: uppercase;
  letter-spacing: 6px;
  margin-bottom: 10px;
}

.testimonials-box .head-box h4 {
  font-size: 32px;
  font-weight: 400;
  color: #fff;
  margin-bottom: 10px;
}

.testimonials-box .head-box h4 span {
  color: #222;
}

.line {
  width: 60px;
  border-top: 1px solid rgba(255, 255, 255, 0.2) !important;
  margin-bottom: 10px;
}

.testimonials h5 {
  color: #fff;
  font-size: 24px;
  margin-bottom: 30px;
}

@media screen and (max-width: 991px) {
  .testimonials-box {
    margin-bottom: 90px;
    padding: 35px 20px;
  }
}
.testimonials .item {
  margin-bottom: 30px;
  position: relative;
}

.testimonials .item .quote {
  position: absolute;
  right: 20px;
  top: 90px;
  width: 70px;
  margin: 0 auto 30px;
  opacity: 0.1;
}

.testimonials .item p {
  color: #fff;
}

.testimonials .item .info {
  text-align: left;
  margin: 30px 0 15px 0;
}

.testimonials .item .info .author-img {
  width: 70px;
  height: 70px;
  border-radius: 50%;
  float: left;
  overflow: hidden;
}

.testimonials .item .info .cont {
  margin-left: 85px;
}

.testimonials .item .info h6 {
  font-size: 15px;
  margin-bottom: 0px;
  padding-top: 0px;
  font-weight: 400;
  font-family: "Barlow Condensed", sans-serif;
  color: #fff;
  line-height: 1em;
  text-transform: uppercase;
  letter-spacing: 3px;
}

.testimonials .item .info span {
  font-size: 12px;
  color: #fff;
  line-height: 1em;
}

.testimonials .item .info .rate {
  float: right;
}

.testimonials .item .info i {
  color: #222;
  font-size: 6px;
}

@media screen and (max-width: 767px) {
  .testimonials-box {
    padding: 0px;
  }
}
/* ======= Booking style ======= */
.booking-wrapper {
  position: absolute;
  left: 0;
  width: 100%;
  bottom: 5%;
  z-index: 5;
}

.booking2-wrapper {
  position: relative;
  margin-bottom: 40px;
}

.booking-inner {
  position: relative;
  border-radius: 0;
}

.form1 {
  display: block;
  padding: 0;
  position: relative;
}

.form1 label {
  display: none;
}

.form1 .col1 {
  float: left;
  width: 20%;
}

.form1 .col2 {
  float: left;
  width: 14%;
}

.form1 .col3 {
  float: left;
  width: 18%;
}

.form1 .c1 {
  border-right: 1px solid #f1eeeb;
  height: 62.5px;
  margin-bottom: 15px;
}

.form1 .c2 {
  border-right: 1px solid #f1eeeb;
  height: 62.5px;
  margin-bottom: 15px;
}

.form1 .c3 {
  border-right: 1px solid #f1eeeb;
  height: 62.5px;
  margin-bottom: 15px;
}

.form1 .c4 {
  border-right: 1px solid #f1eeeb;
  height: 62.5px;
  margin-bottom: 15px;
}

.form1 .c5 {
  border-right: 1px solid #f1eeeb;
  height: 62.5px;
  margin-bottom: 15px;
}

.ui-datepicker .ui-datepicker-header {
  background: #1ac4ff;
  border: #1ac4ff;
  color: #fff;
}

.ui-datepicker {
  font-size: 15px;
  color: #222;
  background: #fff;
  z-index: 100 !important;
  border: 1px solid #fff;
  border-radius: 0px;
}

.ui-state-default,
.ui-widget-content .ui-state-default,
.ui-widget-header .ui-state-default {
  border: 1px solid #f8f5f0;
  background: #f8f5f0;
  color: #222;
}

.ui-state-hover,
.ui-widget-content .ui-state-hover,
.ui-widget-header .ui-state-hover,
.ui-state-focus,
.ui-widget-content .ui-state-focus,
.ui-widget-header .ui-state-focus {
  border: 1px solid #1ac4ff;
  background: #1ac4ff;
  font-weight: normal;
  color: #fff;
}

.ui-datepicker .ui-datepicker-prev:after {
  font-size: 10px;
  content: "\e64a";
  font-family: "themify";
  color: #fff;
}

.ui-datepicker .ui-datepicker-next:before {
  font-size: 10px;
  content: "\e649";
  font-family: "themify";
  color: #fff;
  float: right;
}

.ui-datepicker .ui-datepicker-prev:hover,
.ui-datepicker .ui-datepicker-next:hover {
  background: transparent;
  border: 1px solid transparent;
}

.btn-form1-submit {
  display: block;
  padding: 0;
  border: none;
  margin: 0;
  width: 100%;
  background: #1ac4ff;
  color: #fff;
  font-family: "Barlow Condensed", sans-serif;
  font-size: 15px;
  text-decoration: none;
  line-height: 62.5px;
  position: relative;
  cursor: pointer;
  transition: all 0.3s ease-out;
  letter-spacing: 3px;
  text-transform: uppercase;
}

.btn-form1-submit:hover {
  background: #222;
}

.btn-form1-submit:focus {
  outline: none;
}

.btn-form1-submit:after {
  content: "";
  display: block;
  width: 1px;
  height: 100%;
  position: absolute;
  left: 100%;
  top: 0;
  background: inherit;
}

.input1_inner {
  position: relative;
  position: relative;
}

.input1_inner:after {
  content: "";
  display: block;
  position: absolute;
  right: 20px;
  top: 23px;
  pointer-events: none;
  content: "\e6b6";
  font-family: "themify";
  font-size: 15px;
  color: #1ac4ff;
  line-height: 1;
}

.input1_inner input {
  display: block;
  width: 100%;
  font-size: 15px;
  color: #222;
  padding: 20px 40px 20px 20px;
  background: #fff;
  border: none;
  box-shadow: none;
  border-radius: 0;
}

.input1_inner input::placeholder {
  font-size: 15px;
  color: #222 !important;
}

.input1_inner input:focus {
  outline: none;
  box-shadow: none;
  background: #fff;
  color: #222 !important;
}

.select1_wrapper {
  margin-bottom: 0;
}

.select1_inner {
  position: relative;
}

.select1_inner:after {
  content: "";
  display: block;
  position: absolute;
  right: 20px;
  top: 25px;
  pointer-events: none;
  content: "\e64b";
  font-family: "themify";
  font-size: 10px;
  color: #1ac4ff;
  font-weight: normal;
  line-height: 15px;
}

.select2 {
  width: 100%;
  background: #fff;
  margin-bottom: 15px;
}

.select2 *:focus {
  outline: none !important;
}

.select2-container--default .select2-results > .select2-results__options {
  max-height: 500px;
}

.select2-container--default .select2-selection--single {
  background: none;
  border-radius: 0;
  border: none;
  height: auto;
}

.selection {
  display: inline-flex;
}

.select2-container--default .select2-selection--single .select2-selection__rendered {
  font-size: 15px;
  line-height: 28px;
  padding: 17px 49px 17px 20px;
  color: #222;
}

.select2-container--default .select2-selection--single .select2-selection__arrow {
  display: none;
}

.select2-dropdown {
  background: none;
  border-radius: 0;
  z-index: 100;
  min-width: 0px;
  border: none;
}

.select2-container--open .select2-dropdown--above {
  border-bottom: 2px solid #1ac4ff;
}

.select2-container--open .select2-dropdown--below {
  border-top: 2px solid #1ac4ff;
}

.select2-results__option {
  padding: 10px 30px;
  background: #fff;
  font-size: 15px;
  line-height: 20px;
  color: #222;
  border-top: 1px solid #ececec;
  text-align: center;
}

.select2-results__option:first-child {
  border-top: none;
}

.small .select2-results__option {
  padding-left: 0;
}

.select2-container--default .select2-results__option[aria-selected=true] {
  background: #1ac4ff;
  color: #fff;
}

.select2-container--default .select2-results__option--highlighted[aria-selected] {
  background: #1ac4ff;
  color: #fff;
}

/* responsive */
@media (max-width: 1259.98px) {
  .booking2-wrapper {
    margin-bottom: 30px;
  }
}
@media (max-width: 991.98px) {
  .booking2-wrapper {
    margin-bottom: 40px;
  }

  .form1 .col1 {
    width: 50%;
    border-bottom: 1px solid #ececec;
  }

  .form1 .c2 {
    border-right: none;
  }

  .form1 .col2 {
    width: 25%;
  }

  .form1 .col3 {
    width: 25%;
  }
}
@media (max-width: 767.98px) {
  .form1 .col1 {
    width: 100%;
    border-right: 0;
    border-bottom: 1px solid #ececec;
  }

  .form1 .col2 {
    width: 100%;
    border-right: 0;
    border-bottom: 1px solid #ececec;
  }

  .form1 .col3 {
    width: 100%;
    border-right: 0;
  }

  .btn-form1-submit:after {
    background: none;
  }

  .booking-wrapper {
    position: relative;
    bottom: auto;
    padding: 30px 0 0;
  }
}
/* booking form */
.booking-box {
  position: relative;
  background: white;
  padding: 60px 45px;
  margin-bottom: -75px;
}

.booking-box .head-box h6 {
  font-size: 15px;
  font-family: "Barlow Condensed", sans-serif;
  font-weight: 400;
  color: #666;
  text-transform: uppercase;
  position: relative;
  letter-spacing: 6px;
  margin-bottom: 10px;
}

.booking-box .head-box h4 {
  font-family: "Gilda Display", serif;
  font-size: 27px;
  font-weight: 400;
  color: #222;
  border-bottom: 1px solid #ececec;
  padding-bottom: 15px;
  letter-spacing: 0;
  text-transform: none;
  margin-top: 0;
}

.booking-box .head-box h4 span {
  color: #222;
}

@media screen and (max-width: 991px) {
  .booking-box {
    margin-bottom: 90px;
    padding: 35px 20px;
  }
}
/* ======= Pricing style ======= */
.pricing-card {
  background-color: #f8f5f0;
  margin-bottom: 30px;
}

.pricing-card .desc {
  padding: 40px;
}

.pricing-card .name {
  line-height: 1;
  margin: 0;
  font-family: "Gilda Display", serif;
  font-size: 24px;
  color: #222;
  margin-bottom: 20px;
}

.pricing-card .subname {
  line-height: 1;
  margin: 0;
  font-size: 12px;
  text-transform: uppercase;
  color: #666;
  margin-bottom: 20px;
  letter-spacing: 3px;
}

.pricing-card .amount {
  margin: 0;
  line-height: 1;
  font-family: "Gilda Display", serif;
  font-size: 40px;
  margin-bottom: 20px;
  color: #1ac4ff;
}

.pricing-card .amount span {
  font-family: "Barlow", sans-serif;
  font-size: 15px;
  color: #666;
}

.pricing-card .list {
  text-align: left;
  margin-left: auto;
  margin-right: auto;
  margin-bottom: 0px;
}

.pricing-card .list li {
  position: relative;
  font-size: 15px;
  padding-left: 30px;
}

.pricing-card .list li + li {
  margin-top: 5px;
}

.pricing-card .list li i {
  position: absolute;
  top: 50%;
  left: 0;
  transform: translateY(-50%);
  font-size: 15px;
  color: #1ac4ff;
}

.pricing-card .list li i.unavailable {
  color: #666;
}

/* ======= Careers style ======= */
.position {
  position: relative;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  margin-top: -1px;
  padding: 40px 45px 40px 50px;
  -webkit-transition: background-color 0.3s;
  transition: background-color 0.3s;
  border-top: 1px solid #f1eeeb;
  border-bottom: 1px solid #f1eeeb;
  background-color: transparent;
  -webkit-box-pack: justify;
  -webkit-justify-content: space-between;
  -ms-flex-pack: justify;
  justify-content: space-between;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-flex-wrap: wrap;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
}

.position-link {
  position: absolute;
  z-index: 1;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.position-title {
  font-family: "Gilda Display", serif;
  font-size: 24px;
  font-weight: 400;
  position: relative;
  -webkit-transition: color 0.3s;
  transition: color 0.3s;
  -webkit-flex-basis: 30%;
  -ms-flex-preferred-size: 30%;
  flex-basis: 30%;
  color: #222;
}

.position-title span {
  font-family: "Barlow", sans-serif;
  color: #666;
  font-size: 15px;
  display: block;
  text-transform: none;
  letter-spacing: 0px;
}

.position-location {
  font-family: "Barlow", sans-serif;
  font-size: 15px;
  -webkit-transition: color 0.3s;
  transition: color 0.3s;
  -webkit-flex-basis: 40%;
  -ms-flex-preferred-size: 40%;
  flex-basis: 40%;
  color: #666;
}

.position-location span {
  font-family: "Barlow", sans-serif;
  font-size: 15px;
  color: #222;
  display: block;
  text-transform: none;
  letter-spacing: 0px;
}

.position-time {
  font-weight: 400;
  -webkit-transition: color 0.3s;
  transition: color 0.3s;
  color: #222;
}

.position-time span {
  font-family: "Barlow", sans-serif;
  color: #666;
  display: block;
  text-transform: none;
  letter-spacing: 0px;
}

.position-icon {
  font-size: 15px;
  line-height: 1;
  color: #1ac4ff;
}

.position-icon i {
  display: inline-block;
}

/* hover */
.position:hover {
  background-color: #1ac4ff;
}

.position:hover .position-title,
.position:hover .position-title span,
.position:hover .position-location,
.position:hover .position-location span,
.position:hover .position-time,
.position:hover .position-time span,
.position:hover .position-icon {
  color: #fff;
}

@media only screen and (max-width: 991px) {
  .position {
    flex-direction: column;
    -webkit-box-pack: initial;
    -webkit-justify-content: initial;
    -ms-flex-pack: initial;
    justify-content: initial;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    -webkit-box-align: start;
    -webkit-align-items: flex-start;
    -ms-flex-align: start;
    align-items: flex-start;
  }

  .position-title {
    -webkit-flex-basis: 100%;
    -ms-flex-preferred-size: 100%;
    flex-basis: 100%;
  }

  .position-location {
    margin-top: 15px;
    -webkit-flex-basis: 100%;
    -ms-flex-preferred-size: 100%;
    flex-basis: 100%;
  }

  .position-time {
    margin-top: 15px;
    -webkit-flex-basis: 100%;
    -ms-flex-preferred-size: 100%;
    flex-basis: 100%;
  }

  .position-icon {
    display: none;
  }
}
/* ======= Accordion Box (for Faqs) style ======= */
.accordion-box {
  position: relative;
}

.accordion-box .block {
  position: relative;
  background: #fff;
  border-radius: 0px;
  overflow: hidden;
  margin-bottom: 15px;
}

.accordion-box .block.active-block {
  background-color: #fff;
}

.accordion-box .block:last-child {
  margin-bottom: 0;
}

.accordion-box .block .acc-btn {
  position: relative;
  font-size: 20px;
  line-height: 27px;
  font-weight: 400;
  cursor: pointer;
  padding: 20px 40px;
  padding-right: 60px;
  color: #222;
  transition: all 500ms ease;
  font-family: "Gilda Display", serif;
}

.accordion-box .block .acc-btn .count {
  color: #1ac4ff;
  padding-right: 3px;
}

.accordion-box .block .acc-btn:before {
  position: absolute;
  right: 35px;
  top: 20px;
  height: 30px;
  font-size: 14px;
  font-weight: normal;
  color: #1ac4ff;
  line-height: 30px;
  content: "\e61a";
  font-family: "Themify";
  transition: all 500ms ease;
}

.accordion-box .block .acc-btn.active {
  background-color: #fff;
  color: #1ac4ff;
}

.accordion-box .block .acc-btn.active:before {
  color: "Themify";
  content: "\e622";
}

.accordion-box .block .acc-content {
  position: relative;
  display: none;
}

.accordion-box .block .content {
  position: relative;
  padding: 0px 40px 30px;
  background-color: #fff;
  color: #666;
}

.accordion-box .block .acc-content.current {
  display: block;
}

.accordion-box .block .content .text {
  display: block;
  position: relative;
  top: 0px;
  display: block;
}

/* ======= News style ======= */
.news .item {
  position: relative;
  overflow: hidden;
  margin-bottom: 30px;
}

.news .item:hover img {
  -webkit-filter: none;
  filter: none;
  -webkit-transform: scale(1.09, 1.09);
  transform: scale(1.09, 1.09);
  -webkit-filter: brightness(75%);
  -webkit-transition: all 1s ease;
  -moz-transition: all 1s ease;
  -o-transition: all 1s ease;
  -ms-transition: all 1s ease;
  transition: all 1s ease;
}

.news .item:hover .con {
  bottom: 0;
}

.news .item img {
  -webkit-transition: all 0.5s;
  transition: all 0.5s;
  -webkit-filter: brightness(100%);
}

.news .item .date {
  display: inline-block;
  position: absolute;
  top: 20px;
  left: 20px;
  background: transparent;
  border: 1px solid rgba(255, 255, 255, 0.5);
  color: #fff;
  padding: 5px 10px;
  text-align: center;
}

.news .item .date span {
  font-family: "Barlow Condensed", sans-serif;
  display: block;
  font-size: 12px;
  text-transform: uppercase;
  letter-spacing: 3px;
  color: #fff;
}

.news .item .date i {
  font-family: "Barlow Condensed", sans-serif;
  display: block;
  font-size: 24px;
  font-style: normal;
  color: #fff;
}

.news .item .con {
  margin: -155px 20px 90px;
  padding: 20px;
  background-color: #f8f5f0 !important;
  border: 1px solid #f8f5f0;
  border-radius: 0;
  overflow: hidden;
  -webkit-transition: 0.2s cubic-bezier(0.3, 0.58, 0.55, 1);
  transition: 0.2s cubic-bezier(0.3, 0.58, 0.55, 1);
  bottom: -90px;
  position: relative;
}

.news .item .con .category {
  font-family: "Barlow", sans-serif;
  text-transform: uppercase;
  font-size: 12px;
  font-weight: 400;
  margin-bottom: 5px;
  letter-spacing: 3px;
}

.news .item .con .category a {
  margin-right: 5px;
  position: relative;
  color: #666;
}

.news .item .con .category a:hover {
  color: #222;
}

.news .item .con .category a:last-child {
  margin-right: 0;
}

.news .item .con .category a:last-child:after {
  display: none;
}

.news .item .con h5,
.news .item .con h5 a {
  color: #222;
  font-size: 24px;
  font-family: "Gilda Display", serif;
  font-weight: 400;
  margin-bottom: 0px;
}

.news .item .con h5:hover,
.news .item .con h5 a:hover {
  color: #1ac4ff;
}

.news .item .con p {
  color: #fff;
  font-size: 17px;
}

.news .item .con i {
  color: #fff;
  font-size: 20px;
}

/* ======= News 2 style ======= */
.news2 .item {
  margin-bottom: 30px;
}

.news2 .post-img {
  position: relative;
  overflow: hidden;
}

.news2 .post-img:hover img {
  -webkit-filter: none;
  filter: none;
  -webkit-transform: scale(1.05, 1.05);
  transform: scale(1.05, 1.05);
  -webkit-transition: all 1s ease;
  -moz-transition: all 1s ease;
  -o-transition: all 1s ease;
  -ms-transition: all 1s ease;
  transition: all 1s ease;
}

.news2 .post-img a {
  display: block;
}

.news2 .post-img img {
  -webkit-transition: all 0.5s;
  transition: all 0.5s;
}

.news2 .post-img .date {
  display: inline-block;
  position: absolute;
  bottom: 20px;
  left: 20px;
  background: transparent;
  border: 1px solid rgba(255, 255, 255, 0.5);
  color: #fff;
  padding: 5px 10px;
  text-align: center;
}

.news2 .post-img .date span {
  font-family: "Barlow Condensed", sans-serif;
  display: block;
  font-size: 12px;
  text-transform: uppercase;
  letter-spacing: 3px;
  color: #fff;
}

.news2 .post-img .date i {
  font-family: "Barlow Condensed", sans-serif;
  display: block;
  font-size: 24px;
  font-style: normal;
  color: #fff;
}

.news2 .post-cont {
  padding: 30px 0;
}

.news2 .post-cont .tag {
  display: block;
  font-weight: 400;
  font-size: 12px;
  letter-spacing: 3px;
  color: #1ac4ff;
  font-family: "Barlow", sans-serif;
  text-transform: uppercase;
  margin-bottom: 5px;
}

.news2 .post-cont h5 {
  font-size: 30px;
}

.news2 .post-cont h5 a {
  color: #222;
}

.news2 .post-cont h5 a:hover {
  color: #1ac4ff;
}

.news2 .post-cont .date {
  font-family: "Barlow", sans-serif;
  margin-top: 0px;
  font-size: 12px;
  text-align: right;
  letter-spacing: 3px;
  text-transform: uppercase;
  margin-bottom: 5px;
}

.news2 .post-cont .date a {
  color: #1ac4ff;
}

.news2 .post-cont i {
  color: #ccc;
  margin: 0 10px;
  font-size: 12px;
}

/* news sidebar */
.news2-sidebar .widget {
  background: #f8f5f0;
  padding: 30px;
  margin-bottom: 30px;
  overflow: hidden;
}

.news2-sidebar .widget ul {
  margin-bottom: 0;
  padding: 0;
}

.news2-sidebar .widget ul li {
  margin-bottom: 15px;
  color: #666;
  font-size: 15px;
  line-height: 1.5em;
}

.news2-sidebar .widget ul li a {
  color: #666;
}

.news2-sidebar .widget ul li a.active {
  color: #1ac4ff;
}

.news2-sidebar .widget ul li a:hover {
  color: #1ac4ff;
}

.news2-sidebar .widget ul li:last-child {
  margin-bottom: 0;
}

.news2-sidebar .widget ul li i {
  font-size: 9px;
  margin-right: 10px;
}

.news2-sidebar .widget .recent li {
  display: block;
  overflow: hidden;
}

.news2-sidebar .widget .recent .thum {
  width: 90px;
  overflow: hidden;
  float: left;
}

.news2-sidebar .widget .recent a {
  display: block;
  margin-left: 105px;
}

.news2-sidebar .widget-title {
  margin-bottom: 20px;
}

.news2-sidebar .widget-title h6 {
  padding-bottom: 5px;
  border-bottom: 1px solid #eee3d0;
  font-size: 24px;
  line-height: 1.75em;
  margin-bottom: 15px;
  color: #222;
}

.news2-sidebar .search form {
  position: relative;
}

.news2-sidebar .search form input {
  width: 100%;
  padding: 10px;
  border: 0;
  background: #fff;
  margin-bottom: 0;
}

.news2-sidebar .search form button {
  position: absolute;
  right: 0;
  top: 0;
  background-color: transparent;
  color: #222;
  border: 0;
  padding: 10px;
  cursor: pointer;
}

.news2-sidebar .tags li {
  margin: 3px !important;
  padding: 8px 20px;
  background-color: #fff;
  border: 1px solid #fff;
  color: #666 !important;
  float: left;
}

.news2-sidebar ul.tags li a {
  font-size: 14px;
  color: #666;
}

.news2-sidebar ul.tags li:hover,
.news2-sidebar ul.tags li a:hover {
  background-color: #1ac4ff;
  color: #fff;
}

/* pagination */
.news-pagination-wrap {
  padding: 0;
  margin: 0;
  text-align: center;
}

.news-pagination-wrap li {
  display: inline-block;
  margin: 0 5px;
}

.news-pagination-wrap li a {
  background: transparent;
  display: inline-block;
  width: 40px;
  height: 40px;
  line-height: 37px;
  text-align: center;
  color: #666;
  font-weight: 400;
  border: 1px solid rgba(102, 102, 102, 0.1);
  font-family: "Barlow", sans-serif;
  font-size: 16px;
  border-radius: 100%;
}

.news-pagination-wrap li a i {
  font-size: 11px;
}

.news-pagination-wrap li a:hover {
  opacity: 1;
  text-decoration: none;
  background: transparent;
  border: 1px solid #1ac4ff;
  color: #1ac4ff;
}

.news-pagination-wrap li a.active {
  background-color: transparent;
  border: 1px solid #1ac4ff;
  color: #1ac4ff;
}

@media screen and (max-width: 768px) {
  .news-pagination-wrap {
    padding: 0;
    margin: 0;
    text-align: center;
  }
}
/* ======= Post style ======= */
.news-post-section {
  padding-top: 120px;
}

.post-comment-section {
  margin-top: 60px;
}

.post-comment-section h3 {
  font-size: 20px;
  color: #222;
  font-family: "Gilda Display", serif;
  margin-bottom: 15px;
}

.post-comment-section h3 span {
  font-family: "Barlow", sans-serif;
  font-size: 12px;
  color: #666;
  margin-left: 15px;
}

.news-post-caption h1 {
  margin-bottom: 20px;
  font-size: 40px;
  text-transform: uppercase;
}

.news-post-comment-wrap {
  display: flex;
  margin-bottom: 60px;
}

.post-user-comment {
  margin-right: 20px;
  flex: 0 0 auto;
}

.post-user-comment img {
  border-radius: 100%;
  width: 100px;
}

.post-user-content {
  margin-right: 26px;
}

.post-repay {
  font-size: 14px;
  color: #1ac4ff;
  margin: 0;
  font-weight: 400;
}

/* ======= Contact style ======= */
.contact h3 {
  font-size: 24px;
  color: #222;
}

.map {
  -webkit-filter: grayscale(100%);
  filter: grayscale(100%);
}

/* ======= Buttons style ======= */
/* dark */
.butn-dark {
  position: relative;
  line-height: 1.2em;
}

.butn-dark a {
  font-weight: 400;
  font-family: "Barlow Condensed", sans-serif;
  text-transform: uppercase;
  background: #2b96cc;
  color: #fff;
  padding: 13px 22px;
  margin: 0;
  position: relative;
  font-size: 15px;
  letter-spacing: 3px;
}

.butn-dark a:after {
  content: "";
  width: 0;
  height: 100%;
  position: absolute;
  bottom: 0;
  left: 100%;
  z-index: 1;
  background: #222;
  color: #fff;
  -webkit-transition: all 0.3s ease;
  transition: all 0.3s ease;
}

.butn-dark a span {
  position: relative;
  z-index: 2;
}

.butn-dark a:hover:after {
  width: 100%;
  left: 0;
  -webkit-transition: width 0.3s ease;
  transition: width 0.3s ease;
}

.butn-dark a:hover span {
  color: #fff;
}

/* light */
.butn-light {
  position: relative;
  line-height: 1.2em;
}

.butn-light a {
  font-weight: 400;
  font-family: "Barlow Condensed", sans-serif;
  text-transform: uppercase;
  background: #2b96cc;
  color: #FFFFFF;
  padding: 12px 20px;
  margin: 0;
  position: relative;
  font-size: 15px;
  letter-spacing: 3px;
  border: 1px solid rgba(255, 255, 255, 0.5);
}

.butn-light a:after {
  content: "";
  width: 0;
  height: 100%;
  position: absolute;
  bottom: 0;
  left: 100%;
  z-index: 1;
  -webkit-transition: all 0.3s ease;
  transition: all 0.3s ease;
  background: #fff;
}

.butn-light a span {
  position: relative;
  z-index: 2;
}

.butn-light a:hover:after {
  width: 100%;
  left: 0;
  -webkit-transition: width 0.3s ease;
  transition: width 0.3s ease;
}

.butn-light a:hover span {
  color: #222;
}

/* ====== Coming Soon ======= */
.comming {
  min-height: 100vh;
  height: auto;
  position: relative;
  background-blend-mode: overlay;
  background: rgba(0, 0, 0, 0.4);
  background-image: url("../img/slider/1.jpg");
  background-size: cover;
  background-position: center;
  background-attachment: fixed;
  color: #fff;
}

@media (max-width: 767px) {
  .comming {
    min-height: 120vh;
  }
}
.comming .item {
  background: transparent;
  border: 1px solid rgba(255, 255, 255, 0.2);
  padding: 30px 15px;
  margin-bottom: 30px;
}

.comming h6 {
  color: #fff;
  font-size: 15px;
  font-family: "Barlow Condensed", sans-serif;
  font-weight: 400;
  text-transform: uppercase;
  margin-bottom: 30px;
  letter-spacing: 3px;
}

.comming h3 {
  font-size: 48px;
  color: #fff;
  position: relative;
  text-transform: uppercase;
  letter-spacing: 3px;
  margin-bottom: 0px;
}

.comming h2 {
  font-size: 40px;
  font-weight: 400;
  color: #fff;
  position: relative;
}

.comming h1 {
  font-size: 200px;
  font-weight: 400;
  margin-bottom: 0px;
  position: relative;
  color: transparent;
  -webkit-text-stroke: 1px #fff;
  opacity: 0.8;
}

.comming p {
  color: #fff;
  font-size: 15px;
}

/* coming form */
.comming form {
  margin-top: 30px;
  position: relative;
}

.comming form input {
  width: 100%;
  padding: 11px 50px 11px 11px;
  background-color: transparent;
  border: 1px solid #1ac4ff;
  border-radius: 0px;
  color: #fff;
  margin-bottom: 15px;
  outline: none;
}

.comming form input::placeholder {
  color: #fff;
}

.comming form button {
  background-color: #1ac4ff;
  padding: 6px 20px;
  border-radius: 0px;
  color: #fff;
  border: 0;
  position: absolute;
  top: 4px;
  right: 4px;
  cursor: pointer;
  font-family: "Barlow Condensed", sans-serif;
  font-size: 15px;
  letter-spacing: 3px;
  text-transform: uppercase;
  font-weight: 400;
  outline: none;
}

.comming form button:hover {
  background-color: #222;
  color: #fff;
  outline: none;
}

/* go home */
.go-back {
  margin-top: 45px;
}

.go-back a {
  color: #fff;
  display: inline-block;
  font-family: "Barlow Condensed", sans-serif;
  font-size: 15px;
  text-transform: uppercase;
  letter-spacing: 3px;
}

.go-back a:hover {
  color: #1ac4ff;
}

.go-back a span {
  transition: all 0.5s;
}

.go-back a span i {
  color: #fff;
  font-size: 12px;
}

.go-back a:hover span {
  padding-right: 15px;
}

/* ======= 404 Page style ======= */
.error-form {
  position: relative;
  max-width: 520px;
  margin: 50px auto 0;
}

.error-form form {
  position: relative;
}

.error-form .form-group {
  position: relative;
  margin: 0;
}

.error-form .form-group input[type=text],
.error-form .form-group input[type=search],
.error-form .form-group input[type=email] {
  position: relative;
  width: 100%;
  line-height: 30px;
  padding: 20px 60px 20px 30px;
  height: 70px;
  display: block;
  font-family: "Barlow", sans-serif;
  font-size: 15px;
  background: #f4f4f4;
  color: #666;
  border-radius: 7px;
  border: 1px solid #f4f4f4;
  transition: all 300ms ease;
}

.error-form .form-group input[type=text]:focus,
.error-form .form-group input[type=email]:focus,
.error-form .form-group input[type=search]:focus {
  border-color: #f4f4f4;
}

.error-form .form-group input::-webkit-input-placeholder {
  color: #f4f4f4;
}

.error-form .form-group .theme-btn {
  position: absolute;
  right: 20px;
  top: 10px;
  width: 40px;
  height: 40px;
  line-height: 40px;
  font-size: 20px;
  background: none;
  color: #666;
}

.error-form .form-group .theme-btn span {
  vertical-align: middle;
  outline: none;
}

/* ======= Footer style ======= */
.footer {
  position: relative;
  display: block;
}

.footer-top {
  position: relative;
  display: block;
  background-color: #888;
  padding: 90px 0 60px 0;
  z-index: 1;
}

.footer-top .footer-column {
  margin-bottom: 30px;
}

.footer-title {
  font-size: 24px;
  color: #fff;
  margin-bottom: 20px;
}

.footer-about-text {
  font-size: 15px;
  color: #ffffff;
  margin: 0;
}

.footer-about-social-list {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  margin-top: 20px;
}

.footer-about-social-list a {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  text-align: center;
  color: #fff;
  font-size: 20px;
  background-color: transparent;
  -webkit-transition: all 500ms ease;
  transition: all 500ms ease;
}

.footer-about-social-list a + a {
  margin-left: 15px;
}

.footer-about-social-list a:hover {
  background-color: transparent;
  color: #282828;
}

.footer-explore-list li + li {
  margin-top: 3px;
}

.footer-explore-list li a {
  position: relative;
  display: block;
  font-size: 15px;
  color: #ffffff;
  -webkit-transition: all 500ms ease;
  transition: all 500ms ease;
}

.footer-explore-list li a:hover {
  padding-left: 15px;
  color: #1ac4ff;
}

.footer-explore-list li a:before {
  position: absolute;
  top: 55%;
  left: 0;
  bottom: 0;
  width: 6px;
  height: 6px;
  content: "";
  background-color: #282828;
  -webkit-transform: translateY(-50%);
  transform: translateY(-50%);
  opacity: 0;
  border-radius: 50%;
  -webkit-transition: all 500ms ease;
  transition: all 500ms ease;
}

.footer-explore-list li a:hover:before {
  opacity: 1;
}

.footer-contact {
  position: relative;
  display: block;
}

.footer-contact .footer-title {
  margin-bottom: 20px;
}

.footer-contact-text {
  font-size: 15px;
  margin: 0;
  color: #ffffff;
  padding-bottom: 20px;
}

.footer-contact-info p {
  margin: 0;
}

.footer-contact-phone {
  font-family: "Gilda Display", serif;
  font-size: 24px;
  color: #fff;
  font-weight: 400;
  display: block;
  -webkit-transition: all 500ms ease;
  transition: all 500ms ease;
}

.footer-contact-phone:hover {
  color: #fff;
}

.footer-contact-mail {
  font-size: 15px;
  line-height: 3em;
  color: #fff;
  position: relative;
  display: inline-block;
  -webkit-transition: all 500ms ease;
  transition: all 500ms ease;
}

.footer-contact-mail:hover {
  color: #fff;
}

.footer-contact-mail:before {
  position: absolute;
  bottom: 9px;
  left: 0;
  right: 0;
  height: 1px;
  background-color: #1ac4ff;
  content: "";
  -webkit-transition: all 500ms ease;
  transition: all 500ms ease;
}

.footer-contact-mail:hover:before {
  background-color: #5c614a;
}

/* language */
.footer-language {
  width: 160px;
  display: flex;
  flex-wrap: wrap;
  position: relative;
  margin-top: 30px;
}

.footer-language i {
  position: absolute;
  right: 30px;
  top: 17px;
  font-size: 15px;
  opacity: 0.6;
  color: #ffffff;
}

.footer-language select {
  height: 50px;
  background: #0e2c3b;
  color: #ffffff;
  border: 1px solid #0e2c3b;
  border-radius: 0px;
}

.footer-language select option {
  color: #fff;
}

select {
  width: 160px;
  max-width: 100%;
  height: 70px;
  padding: 0 30px;
  border: 1px solid #adadad;
  color: #adadad;
  -webkit-appearance: none;
  -moz-appearance: none;
}

.footer-bottom {
  position: relative;
  display: block;
  background-color: #0e2c3b;
  -webkit-box-shadow: 0px -1px 0px 0px rgba(255, 255, 255, 0.05);
  box-shadow: 0px -1px 0px 0px rgba(255, 255, 255, 0.05);
  padding: 32px 0;
}

.footer-bottom-inner {
  position: relative;
  display: block;
  text-align: center;
}

.footer-bottom-copy-right {
  font-size: 12px;
  color: #ffffff;
  margin: 0;
}

.footer-bottom-copy-right a {
  color: #fff;
  -webkit-transition: all 500ms ease;
  transition: all 500ms ease;
}

.footer-bottom-copy-right a:hover {
  color: #2b96cc;
}

/* ======= toTop Button style ======= */
.progress-wrap {
  position: fixed;
  bottom: 30px;
  right: 30px;
  height: 45px;
  width: 45px;
  cursor: pointer;
  display: block;
  border-radius: 50px;
  -webkit-box-shadow: inset 0 0 0 1px rgba(0, 0, 0, 0.2);
  box-shadow: inset 0 0 0 1px rgba(0, 0, 0, 0.2);
  z-index: 100;
  opacity: 0;
  visibility: hidden;
  -webkit-transform: translateY(20px);
  -ms-transform: translateY(20px);
  transform: translateY(20px);
  -webkit-transition: all 400ms linear;
  -o-transition: all 400ms linear;
  transition: all 400ms linear;
}

.progress-wrap.active-progress {
  opacity: 1;
  visibility: visible;
  -webkit-transform: translateY(0);
  -ms-transform: translateY(0);
  transform: translateY(0);
}

.progress-wrap::after {
  position: absolute;
  font-family: "themify";
  content: "\e648";
  text-align: center;
  line-height: 45px;
  font-size: 12px;
  font-weight: 700;
  color: #323232;
  left: 0;
  top: 0;
  height: 45px;
  width: 45px;
  cursor: pointer;
  display: block;
  z-index: 1;
  -webkit-transition: all 400ms linear;
  -o-transition: all 400ms linear;
  transition: all 400ms linear;
}

.progress-wrap svg path {
  fill: none;
}

.progress-wrap svg.progress-circle path {
  stroke: rgba(0, 0, 0, 0.2);
  stroke-width: 2;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  -webkit-transition: all 400ms linear;
  -o-transition: all 400ms linear;
  transition: all 400ms linear;
}

.progress-wrap {
  -webkit-box-shadow: inset 0 0 0 1px rgba(0, 0, 0, 0.2);
  box-shadow: inset 0 0 0 1px rgba(0, 0, 0, 0.2);
}

.progress-wrap::after {
  color: #282828;
}

.progress-wrap svg.progress-circle path {
  stroke: #282828;
}

/* =======  Overlay Effect Bg image style  ======= */
[data-overlay-dark],
[data-overlay-darkgray],
[data-overlay-light] {
  position: relative;
}

[data-overlay-dark] .container,
[data-overlay-darkgray] .container,
[data-overlay-light] .container {
  position: relative;
  z-index: 2;
}

[data-overlay-dark]:before,
[data-overlay-darkgray]:before,
[data-overlay-light]:before {
  content: "";
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  z-index: 1;
}

[data-overlay-dark]:before {
  background: #000;
}

[data-overlay-darkgray]:before {
  background: #222;
}

[data-overlay-light]:before {
  background: #fff;
}

[data-overlay-dark] h1,
[data-overlay-dark] h2,
[data-overlay-dark] h3,
[data-overlay-dark] h4,
[data-overlay-dark] h5,
[data-overlay-dark] h6,
[data-overlay-dark] span {
  color: #fff;
}

[data-overlay-darkgray] h1,
[data-overlay-darkgray] h2,
[data-overlay-darkgray] h3,
[data-overlay-darkgray] h4,
[data-overlay-darkgray] h5,
[data-overlay-darkgray] h6,
[data-overlay-darkgray] span {
  color: #fff;
}

[data-overlay-light] h1,
[data-overlay-light] h2,
[data-overlay-light] h3,
[data-overlay-light] h4,
[data-overlay-light] h5,
[data-overlay-light] h6,
[data-overlay-light] span {
  color: #999;
}

[data-overlay-dark] p {
  color: #fff;
}

[data-overlay-darkgray] p {
  color: #999;
}

[data-overlay-light] p {
  color: #999;
}

[data-overlay-dark="0"]:before,
[data-overlay-darkgray="0"]:before,
[data-overlay-light="0"]:before {
  opacity: 0;
}

[data-overlay-dark="1"]:before,
[data-overlay-darkgray="1"]:before,
[data-overlay-light="1"]:before {
  opacity: 0.1;
}

[data-overlay-dark="2"]:before,
[data-overlay-darkgray="2"]:before,
[data-overlay-light="2"]:before {
  opacity: 0.2;
}

[data-overlay-dark="3"]:before,
[data-overlay-darkgray="3"]:before,
[data-overlay-light="3"]:before {
  opacity: 0.3;
}

[data-overlay-dark="4"]:before,
[data-overlay-darkgray="4"]:before,
[data-overlay-light="4"]:before {
  opacity: 0.4;
}

[data-overlay-dark="5"]:before,
[data-overlay-darkgray="5"]:before,
[data-overlay-light="5"]:before {
  opacity: 0.5;
}

[data-overlay-dark="6"]:before,
[data-overlay-darkgray="6"]:before,
[data-overlay-light="6"]:before {
  opacity: 0.6;
}

[data-overlay-dark="7"]:before,
[data-overlay-darkgray="7"]:before,
[data-overlay-light="7"]:before {
  opacity: 0.7;
}

[data-overlay-dark="8"]:before,
[data-overlay-darkgray="8"]:before,
[data-overlay-light="8"]:before {
  opacity: 0.8;
}

[data-overlay-dark="9"]:before,
[data-overlay-darkgray="9"]:before,
[data-overlay-light="9"]:before {
  opacity: 0.9;
}

[data-overlay-dark="10"]:before,
[data-overlay-darkgray="10"]:before,
[data-overlay-light="10"]:before {
  opacity: 1;
}

.proximamente {
  background-color: #f9e36a;
  padding-right: 16px;
  padding-top: 4px;
  padding-bottom: 4px;
  color: black !important;
  border-radius: 4px;
}

.proximamente img {
  width: 30px !important;
  display: inline !important;
}

/* ======= Responsive style ======= */
@media screen and (max-width: 991px) {
  .bg-fixed {
    background-attachment: scroll !important;
  }

  .navbar .logo {
    text-align: left;
    margin-left: 0px;
  }

  .nav-scroll .logo {
    width: 150px;
  }

  .logo-img {
    margin-bottom: 0px;
  }

  .navbar button {
    margin-right: 15px;
    outline: none !important;
    background: transparent;
  }

  .navbar button:hover {
    background: transparent;
  }

  .navbar .container {
    max-width: 100%;
  }

  .navbar .navbar-collapse {
    max-height: 360px;
    overflow: auto;
    background: #fff;
    text-align: left;
    padding: 15px 30px 30px 15px;
  }

  .navbar .nav-link {
    margin: 0px auto !important;
  }

  .navbar .navbar-nav .nav-link {
    color: #222;
  }

  .nav-scroll .navbar-collapse .nav-link {
    color: #222 !important;
  }

  .nav-scroll .navbar-collapse .active {
    color: #1ac4ff !important;
  }

  .dropdown-menu {
    opacity: 1;
    visibility: visible;
    -webkit-transform: translateY(0);
    transform: translateY(0);
    width: 100%;
    display: none;
  }

  .dropdown-menu .sub-menu {
    position: static;
    margin-top: 15px;
    opacity: 1;
    visibility: visible;
    -webkit-transform: translateY(0);
    transform: translateY(0);
    width: 100%;
    display: none;
  }

  .dropdown:hover .dropdown-menu,
.dropdown:hover .mega-menu {
    display: block;
  }

  .dropdown .dropdown-menu:hover .sub-menu {
    display: block;
  }

  .dropdown .mega-menu {
    width: 100%;
    position: static;
    opacity: 1;
    visibility: visible;
    -webkit-transform: translateY(0);
    transform: translateY(0);
    width: 100%;
    display: none;
  }

  .dropdown .mega-menu .dropdown-menu {
    border: 0;
    margin: 0;
  }

  .dropdown .mega-menu .dropdown-title {
    border-bottom-color: #666;
  }

  .dropdown-menu .dropdown-item,
.sub-menu .dropdown-item {
    color: #666;
  }

  .header {
    background-attachment: scroll !important;
    background-position: 50% 0% !important;
  }

  .header .caption h1 {
    font-size: 40px;
  }

  .video-fullscreen-wrap .booking-box,
.kenburns-inner .booking-box,
.banner-header .booking-box {
    display: none;
  }
}
@media screen and (max-width: 767px) {
  .logo-wrapper .logo h2 {
    display: none;
  }

  .header .caption h1 {
    font-size: 30px;
    margin-bottom: 15px;
    letter-spacing: 10px;
    line-height: 1.35em;
  }

  .header .caption h3 {
    font-size: 30px;
    margin-bottom: 15px;
    letter-spacing: 10px;
    line-height: 1.35em;
  }

  .header .caption h4 {
    font-size: 12px;
  }

  .header .caption p {
    font-size: 15px;
    margin-right: 10px;
  }

  .header .caption .butn-light {
    margin-right: 10px;
  }

  .portfolio .filtering span {
    margin-bottom: 10px;
  }

  .news .item .con h5,
.news .item .con h5 a {
    font-size: 20px;
  }

  .post-comment-section h3 span {
    margin-left: 0px;
  }

  .banner-header .caption {
    text-align: center;
  }

  .video-fullscreen-wrap .caption.textleft,
.video-fullscreen-wrap .caption {
    text-align: center;
  }
}
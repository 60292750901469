/*!
Theme Name: Sandbox - Modern & Multipurpose Bootstrap 5 Template
Theme URI:	https://sandbox.elemisthemes.com/
Version:	3.2.2
Author:		elemis
*/




// User custom styles
@import 'user';